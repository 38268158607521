import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import { TableCell, TableColumn } from '../../common/components/table/Table';
import Badge from '../../common/components/badge/Badge';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Text from '../../common/components/text/Text';
import styles from './ProjectsScreen.module.scss';
import Button from '../../common/components/button/Button';
import { FaEllipsisV, FaShareSquare } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../common/components/pagination/Pagination';
import PaginationTextInfo from '../../common/components/pagination/PaginationTextInfo';
import Loading from '../../common/services/Loading';
import { ProjectsListDto } from '../../api/projects/models/ProjectsListDto';
import { ProjectsSearchCriteria, ColumnField, SearchType, SearchField } from '../../api/projects/models/ProjectsSearchCriteria';
import ProjectService from '../../api/projects/ProjectService';
import { useToasts } from 'react-toast-notifications';
import Dropdown from '../../common/components/dropdown/Dropdown';
import DropdownItem from '../../common/components/dropdown/DropdownItem';
import DateFormat from '../../common/components/dateFormat/dateFormat';
import { ProjectViewModel, RiskLevel } from '../../api/projects/models/ProjectViewModel';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import { useSelector } from 'react-redux';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';
import { API_BASE_URL, DATE_FORMAT_MONTH_YEAR, DATE_TIME_FORMAT_DEFAUT, SEARCH_HISTORY_PAGES } from '../../Config';
import { Filters } from '../dasboard/components/ProjectAdvancements/ProjectAdvancementsTableFilters/ProjectAdvancementsTableFilters';
import { POLICIES } from '../../Config';
import { saveAs } from 'file-saver';
import axios from 'axios';
import ProjectsSidebar from './components/ProjectsSidebar';
import { UserSettingsListingViewModel } from '../../api/userSettings/models/UserSettingsListingViewModel';
import ReactTooltip from 'react-tooltip';
import UserSettingsService from '../../api/userSettings/UserSettingsService';
import SearchHistoryService from '../../api/userSearchHistory/SearchHistoryService';
import { IdNameDto } from '../../api/common/models/IdNameDto';
import TableProjectBox from './components/TableProjectBox/TableProjectBox';
import { useDebouncedCallback } from 'use-debounce/lib';
import Utils from '../../common/services/Utils';
import { StatusViewModel } from '../../api/status/models/StatusViewModel';
import { PriorityLevelViewModel } from '../../api/priorityLevels/models/PriorityLevelViewModel';
import { StrategicAxesViewModel } from '../../api/strategicAxes/models/StrategicAxesViewModel';
import { DepartmentViewModel } from '../../api/departments/models/DepartmentViewModel';
import { DomainViewModel } from '../../api/domains/models/DomainViewModel';
import { PartnersViewModel } from '../../api/partners/models/PartnersViewModel';
import { ProductViewModel } from '../../api/products/models/ProductViewModel';
import { ProjectManagerViewModel } from '../../api/projectManagers/models/ProjectManagerViewModel';
import { TypologiesViewModel } from '../../api/typologies/models/TypologiesViewModel';
import { UserViewModel } from '../../api/users/models/UserViewModel';
import StatusService from '../../api/status/StatusService';
import StrategicAxesService from '../../api/strategicAxes/StrategicAxesService';
import ProjectManagersService from '../../api/projectManagers/ProjectManagersService';
import PriorityLevelsService from '../../api/priorityLevels/PriorityLevelsService';
import DepartmentsService from '../../api/departments/DepartmentsService';
import DomainsService from '../../api/domains/DomainsService';
import PartnersService from '../../api/partners/PartnersService';
import ProductsService from '../../api/products/ProductsService';
import ProjectResponsiblesService from '../../api/projectResponsibles/ProjectResponsiblesService';
import TypologiesService from '../../api/typologies/TypologiesService';
import UsersService from '../../api/users/UsersService';
import { ProjectResponsibleViewModel } from '../../api/projectResponsibles/models/ProjectResponsibleViewModel';
import Http from '../../common/services/Http';
import { ProjectResponsibleDsiViewModel } from '../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import ProjectResponsiblesDsiService from '../../api/projectResponsiblesDsi/ProjectResponsiblesDsiService';
import NumberPerPage, { ItemsPerPage } from '../../common/components/pagination/numberPerPage/NumberPerPage';
import { ProjectScreenMode } from '../project/models/ProjectForm';
import Semaphore from './components/Semaphore/Semaphore';
import { SearchHistoryViewModel } from '../../api/userSearchHistory/models/SearchHistoryViewModel';

const ProjectsScreen: React.FC = () => {

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userCanWrite = !!loggedUser?.policies.find(x => x == POLICIES.PROJECT_WRITE);
    const userCanRemove = !!loggedUser?.policies.find(x => x == POLICIES.SETTINGUP_PROJECT_REMOVE_WRITE);

    const { t } = useTranslation();
    const history = useHistory();
    const { addToast } = useToasts();
    const [itemToRemove, setItemToRemove] = useState<ProjectViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [projects, setProjects] = useState<ProjectsListDto[]>([]);
    const [tableColumns, setTableColumns] = useState<TableColumn[]>([]);
    const [filters, setFilters] = useState<Filters>();
    const [filtersTotal, setFiltersTotal] = useState(0);
    const [columnsSettings, setColumnSettings] = useState<UserSettingsListingViewModel[]>([]);
    const [onInit, setOnInit] = useState(true);
    const [tblRerender, setTblRerender] = useState(0);
    const [status, setStatus] = useState<StatusViewModel[]>([]);
    const [projectManagers, setProjectManagers] = useState<ProjectManagerViewModel[]>([]);
    const [priorityLevels, setPriorityLevels] = useState<PriorityLevelViewModel[]>([]);
    const [strategicAxes, setStrategicAxes] = useState<StrategicAxesViewModel[]>([]);
    const [departments, setDepartments] = useState<DepartmentViewModel[]>([]);
    const [domains, setDomains] = useState<DomainViewModel[]>([]);
    const [partners, setPartners] = useState<PartnersViewModel[]>([]);
    const [products, setProducts] = useState<ProductViewModel[]>([]);
    const [responsibles, setResponsibles] = useState<ProjectResponsibleViewModel[]>([]);
    const [responsiblesDsi, setResponsiblesDsi] = useState<ProjectResponsibleDsiViewModel[]>([]);
    const [typologies, setTypologies] = useState<TypologiesViewModel[]>([]);
    const [authors, setAuthors] = useState<UserViewModel[]>([]);
    const [users, setUsers] = useState<UserViewModel[]>([]);
    const [tableScrollLeft, setTableScrollLeft] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPage>(DEFAULT_PAGINATION_ITEMS_PER_PAGE);
    const [research, setResearch] = useState(0);

    const [criteria, setCriteria] = useState<ProjectsSearchCriteria>({
        page: 1,
        itemsPerPage: itemsPerPage,
        orderBy: 'asc',
        orderColumn: 'designation'
    });

    const [forceOrderBy, setForceOrderBy] = useState(0);
    const [orderBy, setOrderBy] = useState<string | undefined>(criteria.orderBy);
    const [orderColumn, setOrderColumn] = useState<string | undefined>(criteria.orderColumn);
    const [totalItems, setTotalItems] = useState(0);

    const getData = async () => {
        if (loggedUser) {
            const userPosition = { groupId: loggedUser.groupId, establishmentId: loggedUser.establishmentId };

            const [status, projectManagers, priorityLevels, strategicAxes, departments, domains, partners, products, responsibles, typologies] =
                await Promise.all([
                    StatusService.getList(userPosition), ProjectManagersService.getList(userPosition), PriorityLevelsService.getList(userPosition),
                    StrategicAxesService.getList(userPosition), DepartmentsService.getList(userPosition), DomainsService.getList(userPosition),
                    PartnersService.getList(userPosition), ProductsService.getList(userPosition), ProjectResponsiblesService.getList(userPosition),
                    TypologiesService.getList(userPosition)
                ]);

            const [authors, users, responsiblesDsi] =
                await Promise.all([
                    UsersService.getAuthors(userPosition),
                    !!userPosition.establishmentId ? UsersService.getAllUsersOfEstablishment(userPosition.establishmentId) : !!userPosition.groupId ? UsersService.getAllUsersOfGroup(userPosition.groupId) : null,
                    ProjectResponsiblesDsiService.getList(userPosition)
                ]);

            setStatus(status.items);
            setProjectManagers(projectManagers.items);
            setPriorityLevels(priorityLevels.items);
            setStrategicAxes(strategicAxes.items);
            setDepartments(departments.items);
            setDomains(domains.items);
            setPartners(partners.items);
            setProducts(products.items);
            setResponsibles(responsibles.items);
            setTypologies(typologies.items);
            setAuthors(authors);
            setUsers(users || []);
            setResponsiblesDsi(responsiblesDsi.items);
        }
    }

    const renderCellStatus = (cell: TableCell) => (
        <div style={{ textAlign: 'center' }}>
            <Badge color={cell.row.statusColor}>{cell.row.status}</Badge>
        </div>
    );

    const renderTableActionCell = (cell: TableCell) => {
        const uniqueId = Utils.newGuid();
        return (
            <Dropdown
                idDropdown={uniqueId}
                useLocationOfItemClicked={true}
                options={
                    <>
                        <DropdownItem onClick={() => { goToProject(cell.row as ProjectViewModel, 'details') }}>{t('common.details')}</DropdownItem>
                        {userCanWrite && <DropdownItem onClick={() => { goToProject(cell.row as ProjectViewModel, 'edit') }}>{t('common.edit')}</DropdownItem>}
                        {userCanWrite && <DropdownItem onClick={() => { goToProject(cell.row as ProjectViewModel, 'copy') }}>{t('common.copy')}</DropdownItem>}
                        {userCanWrite && userCanRemove && <DropdownItem onClick={() => { showRemoveItemDialog(cell.row as ProjectViewModel) }}>{t('common.remove')}</DropdownItem>}
                    </>
                }>
                <FaEllipsisV id={uniqueId} />
            </Dropdown>
        );
    }

    const onOrderList = (column: TableColumn) => {
        const _orderBy = orderColumn !== column.order ? 'asc' : orderBy == 'desc' ? 'asc' : 'desc';
        setOrderColumn(column.order);
        setOrderBy(_orderBy);
        search();
    }

    const saveSearchHistory = async () => {
        const elTable = getTableElement();
        const scrollLeft = elTable ? elTable.scrollLeft : tableScrollLeft;
        criteria.scrollLeft = scrollLeft;
        setCriteria({ ...criteria });
        await SearchHistoryService.saveSearchHistory({ page: SEARCH_HISTORY_PAGES.LIST_PROJECTS.toString(), json: JSON.stringify(criteria) });
    }

    const goToProject = async (item: ProjectViewModel, mode: ProjectScreenMode) => {
        //if we only do the saveSearchHistory() he loses the scope and lost the criteria and filters!!!
        await saveSearchHistory();
        history.push(`/project/${item.id}/${mode}/projects/null`);
    }

    const goToNewProject = async () => {
        //if we only do the saveSearchHistory() he loses the scope and lost the criteria and filters!!!
        await saveSearchHistory();
        history.push(`/project/new`);
    }

    const renderCellDates = (cell: TableCell) => (
        <div className={styles.textAlignLeft}>
            <DateFormat value={cell.row.dateStart} format={DATE_FORMAT_MONTH_YEAR} />
            &nbsp;-&nbsp;
            <DateFormat value={cell.row.dateEnd} format={DATE_FORMAT_MONTH_YEAR} />
        </div>
    );

    const renderCellDate = (cell: TableCell) => (
        <div className={styles.textAlignLeft}>
            <DateFormat value={cell.row.updatedDateProject} format={DATE_TIME_FORMAT_DEFAUT} />
        </div>
    );

    const renderRisk = (cell: TableCell) => (
        <Semaphore allowClick={false} selectedItems={[cell.row.riskLevel]} tooltip={cell.row.riskDescription}></Semaphore>
    );
    const orderByRisk = `(CASE WHEN risk_level = 'LOW' THEN 1 WHEN risk_level = 'MEDIUM' THEN 2 WHEN risk_level = 'HIGH' THEN 3 ELSE 0 end)`;

    const renderCellStrategicAxes = (cell: TableCell) => (cell.row.strategicAxes && renderArrayToText(cell.row.strategicAxes));
    const renderCellDepartments = (cell: TableCell) => (cell.row.departmentsInvolved && renderArrayToText(cell.row.departmentsInvolved));
    const renderCellEstablishments = (cell: TableCell) => (cell.row.concernedEstablishments && renderArrayToText(cell.row.concernedEstablishments));
    const renderCellPartners = (cell: TableCell) => (cell.row.concernedPartners && renderArrayToText(cell.row.concernedPartners));
    const renderCellProducts = (cell: TableCell) => (cell.row.products && renderArrayToText(cell.row.products));
    const renderCellResponsibles = (cell: TableCell) => (cell.row.responsibles && renderArrayToText(cell.row.responsibles));
    const renderCellResponsiblesDsi = (cell: TableCell) => (cell.row.responsiblesDsi && renderArrayToText(cell.row.responsiblesDsi));

    const renderArrayToText = (arrayList: any) => (
        <>
            { arrayList.map((item: IdNameDto, i: number) => {
                return (<Text key={'rcr_' + item.id + '_' + i} className={styles.noMarginPadding}>{item.name}</Text>)
            })}
        </>
    );

    //as colunas que forem adicionadas aqui tem de se adicionar no metodo na api que gera o excel tambem
    const _tableColumns: TableColumn[] = [
        //left columns - show always
        { showAlways: true, field: ColumnField.IDENTIFICATION, searchType: SearchType.TEXT, searchField: SearchField.IDENTIFICATION, order: 'identification', name: t('projects.table.identification'), width: '100', resizable: true, placeholder: '000' },
        { showAlways: true, field: ColumnField.STATUS, searchType: SearchType.MULTISELECT, searchField: SearchField.STATUS, order: 'status', name: t('projects.table.status'), width: '170', resizable: true, renderCell: renderCellStatus },
        { showAlways: true, field: ColumnField.DESIGNATION, searchType: SearchType.TEXT, searchField: SearchField.DESIGNATION, order: 'designation', name: t('projects.table.designation'), minWidth: '250', resizable: false, placeholder: t('projects.table.search') },

        //right colums 
        // a ordem que estão aqui é a ordem que vao aparecer por default
        // isDefault true => visivel por defeito
        { isDefault: true, field: ColumnField.PROJECT_MANAGER, searchType: SearchType.MULTISELECT, searchField: SearchField.PROJECT_MANAGER, order: 'work_responsible', name: t('projects.table.moa'), width: '200', resizable: true },
        { isDefault: true, field: ColumnField.RESPONSIBLES, searchType: SearchType.MULTISELECT, searchField: SearchField.RESPONSIBLE, name: t('projects.table.responsibles'), width: '200', resizable: true, renderCell: renderCellResponsibles },
        { isDefault: true, field: ColumnField.RESPONSIBLES_DSI, searchType: SearchType.MULTISELECT, searchField: SearchField.RESPONSIBLE_DSI, name: t('projects.table.responsibles_dsi'), width: '200', resizable: true, renderCell: renderCellResponsiblesDsi },
        { isDefault: true, field: ColumnField.AUTHOR, searchType: SearchType.MULTISELECT, searchField: SearchField.AUTHOR, order: 'author', name: t('projects.table.author'), width: '200', resizable: true },
        { field: ColumnField.DOMAIN, searchType: SearchType.MULTISELECT, searchField: SearchField.DOMAIN, order: 'domain_project', name: t('projects.table.domain_project'), width: '200', resizable: true },
        { field: ColumnField.PRODUCTS, searchType: SearchType.MULTISELECT, searchField: SearchField.PRODUCT, name: t('projects.table.products'), width: '200', resizable: true, renderCell: renderCellProducts },
        { field: ColumnField.ESTABLISHMENTS, searchType: SearchType.TEXT, searchField: SearchField.SEARCH_ESTABLISHMENT, name: t('projects.table.concerned_establishments'), width: '250', resizable: true, renderCell: renderCellEstablishments },
        { field: ColumnField.PARTNERS, searchType: SearchType.MULTISELECT, searchField: SearchField.PARTNER, name: t('projects.table.concerned_partners'), width: '200', resizable: true, renderCell: renderCellPartners },
        { isDefault: true, field: ColumnField.TYPOLOGY, searchType: SearchType.MULTISELECT, searchField: SearchField.TYPOLOGY, order: 'typology_project', name: t('projects.table.typology_project'), width: '200', resizable: true },
        { field: ColumnField.STRATEGIC_AXES, searchType: SearchType.MULTISELECT, searchField: SearchField.STRATEGIC_AXIS, name: t('projects.table.strategic_axes'), width: '200', resizable: true, renderCell: renderCellStrategicAxes },
        { field: ColumnField.DEPARTMENTS, searchType: SearchType.MULTISELECT, searchField: SearchField.DEPARTMENT, name: t('projects.table.directions_instances_involved'), width: '200', resizable: true, renderCell: renderCellDepartments },
        { field: ColumnField.PRIORITY_LEVEL, searchType: SearchType.MULTISELECT, searchField: SearchField.PRIORITY_LEVEL, order: 'priority_level', name: t('projects.table.priority'), width: '200', resizable: true },
        { field: ColumnField.START_END_DATE, fields: [SearchField.START_DATE, SearchField.END_DATE], searchType: SearchType.RANGE_DATE, searchField: SearchField.START_END_DATE, order: 'date_start', name: t('projects.table.start_end'), minWidth: '220', width: '220', resizable: true, renderCell: renderCellDates, cellAlignment: 'left', columnCellAlignment: 'left' },
        { field: ColumnField.BUDGETINGS, fields: [SearchField.START_BUDGETINGS, SearchField.END_BUDGETINGS], searchType: SearchType.RANGE_VALUE, searchField: SearchField.BUDGETINGS, order: 'budgetings', name: t('projects.table.budgetings'), width: '200', resizable: true, cellAlignment: 'right', columnCellAlignment: 'center', cellFormat: 'money' },
        { field: ColumnField.TOTAL_CHARGES, fields: [SearchField.START_CHARGES, SearchField.END_CHARGES], searchType: SearchType.RANGE_VALUE, searchField: SearchField.TOTAL_CHARGES, order: 'total_charges', name: t('projects.table.charges'), width: '200', resizable: true, cellAlignment: 'right', columnCellAlignment: 'center', cellFormat: 'money', cellFormatUnitByField: 'internalAllocationsUnit' },
        { field: ColumnField.TOTAL_RECURRING, fields: [SearchField.START_RECURRING, SearchField.END_RECURRING], searchType: SearchType.RANGE_VALUE, searchField: SearchField.TOTAL_RECURRING, order: 'total_recurring', name: t('projects.table.recurrents'), width: '200', resizable: true, cellAlignment: 'right', columnCellAlignment: 'center', cellFormat: 'money' },
        { field: ColumnField.INVESTIMENTS, fields: [SearchField.START_INVESTIMENTS, SearchField.END_INVESTIMENTS], searchType: SearchType.RANGE_VALUE, searchField: SearchField.INVESTIMENTS, order: 'investments', name: t('projects.table.investments'), width: '200', resizable: true, cellAlignment: 'right', columnCellAlignment: 'center', cellFormat: 'money' },
        { field: ColumnField.SUBVENTIONS_EXPECTED, fields: [SearchField.START_SUBVENTIONS_EXPECTED, SearchField.END_SUBVENTIONS_EXPECTED], searchType: SearchType.RANGE_VALUE, searchField: SearchField.SUBVENTIONS_EXPECTED, order: 'subventions_expected', name: t('projects.table.SUBVENTIONS_EXPECTED'), width: '200', resizable: true, cellAlignment: 'right', columnCellAlignment: 'center', cellFormat: 'money' },
        { field: ColumnField.SUBVENTIONS_OBTAINED, fields: [SearchField.START_SUBVENTIONS_OBTAINED, SearchField.END_SUBVENTIONS_OBTAINED], searchType: SearchType.RANGE_VALUE, searchField: SearchField.SUBVENTIONS_OBTAINED, order: 'subventions_obtained', name: t('projects.table.SUBVENTIONS_OBTAINED'), width: '200', resizable: true, cellAlignment: 'right', columnCellAlignment: 'center', cellFormat: 'money' },
        { field: ColumnField.DATE_UPDATED_PROJECT, searchType: SearchType.DATE, searchField: SearchField.DATE_UPDATED_PROJECT, order: 'updated_date_project', name: t('projects.table.dateUpdatedProject'), width: '200', resizable: true, renderCell: renderCellDate, cellAlignment: 'left', columnCellAlignment: 'center' },
        { field: ColumnField.USER_UPDATED_PROJECT, searchType: SearchType.MULTISELECT, searchField: SearchField.USER_UPDATED_PROJECT, order: 'user_updated_project', name: t('projects.table.userUpdatedProject'), width: '200', resizable: true },
        { field: ColumnField.RISK, searchType: SearchType.LIGHTS, searchField: SearchField.RISK, order: orderByRisk, name: t('project.project_organization.risk'), minWidth: '100', resizable: true, cellAlignment: 'center', placeholder: t('project.project_organization.risk'), renderCell: renderRisk, defaultSearchValue: [RiskLevel.LOW, RiskLevel.MEDIUM, RiskLevel.HIGH] },
        //actions
        { field: 'action', name: '', isActionColumn: true, width: '75', minWidth: 75, cellAlignment: 'center', preventClick: true, renderCell: renderTableActionCell },
    ];

    const onPageChange = (page: number) => {
        search(page);
    }

    const exportData = async () => {
        try {
            Loading.show();

            getCriteria();
            criteria.columnsExport = tableColumns.filter(col => !col.isActionColumn).map(col => { return { key: col.field, value: col.name } });
            setCriteria({ ...criteria });

            const headers = await Http.getHeaders();
            axios({
                headers: headers,
                method: 'POST',
                responseType: 'arraybuffer',
                url: `${API_BASE_URL}${'/projects/exportData'}`,
                data: criteria //TODO order das colunas
            }).then(res => {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, t('projects.projects_filename'))
                Loading.hide();
            }).catch(error => {
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                Loading.hide();
            });

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getProjects = async () => {
        try {

            Loading.show();

            getCriteria();

            //used to restore scroll position 
            await saveSearchHistory();
            const result = await ProjectService.getList(criteria);

            setTotalItems(result.totalItems);
            setProjects(result.items);
            setTblRerender(tblRerender + 1);

            const elTable = getTableElement();
            if (elTable) {
                elTable.scrollLeft = criteria.scrollLeft ?? 0;
            }

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const getTableElement = () => {
        const el = document.getElementsByClassName('rt-table');
        return el && el.length && el[0] ? el[0] : null;
    }

    const getUserSettings = async () => {
        try {

            Loading.show();
            const resultColumnsSettings = await UserSettingsService.getListingSettings(SEARCH_HISTORY_PAGES.LIST_PROJECTS.toString());
            const resultSearchHistory = await SearchHistoryService.getSearchHistoryForPage(SEARCH_HISTORY_PAGES.LIST_PROJECTS.toString());
            Loading.hide();

            applySearchHistory(resultSearchHistory);
            await applyColumnsSettings(resultColumnsSettings, true);

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
            setFilters({});
            search();
        }
    }

    const applySearchHistory = (result: SearchHistoryViewModel) => {
        if (result && result.json) {
            const auxCriteria = JSON.parse(result.json);
            auxCriteria.scrollLeft && setTableScrollLeft(auxCriteria.scrollLeft);
            auxCriteria.page && setCurrentPage(auxCriteria.page);
            auxCriteria.orderBy && setOrderBy(auxCriteria.orderBy);
            auxCriteria.orderColumn && setOrderColumn(auxCriteria.orderColumn);
            auxCriteria.itemsPerPage && setItemsPerPage(auxCriteria.itemsPerPage);
            setFilters(auxCriteria);
        } else {
            setFilters({});
        }
    }

    const applyColumnsSettings = async (settings?: UserSettingsListingViewModel[], refresh?: boolean) => {
        let auxColumns: TableColumn[] = [];
        let auxColumnsSettings: UserSettingsListingViewModel[] = [];

        if (settings && settings.length) {

            const actions = _tableColumns.find(x => x.isActionColumn);

            auxColumns = [..._tableColumns.filter(x => x.showAlways)];
            settings.filter(x => x.visible && !auxColumns.find(z => (z.searchField ?? z.name) == x.field)).forEach(x => {
                const obj = _tableColumns.find(r => (r.searchField ?? r.name) == x.field);
                if (obj) {
                    auxColumns.push(obj);
                }
            })
            !!actions && auxColumns.push(actions);

            settings.filter(x => !!auxColumns.find(z => (z.searchField ?? z.name) == x.field)).forEach(x => {
                const obj = auxColumns.find(r => (r.searchField ?? r.name) == x.field);
                if (obj) {
                    obj.width = x.width;
                }
            })

            _tableColumns
                .filter(x => !(x.isActionColumn || x.showAlways))
                .forEach((x, i) => {
                    const setting = mapTableColumnToColumnSettings(x, i);
                    const obj = settings.find(r => r.field == (x.searchField ?? x.name));
                    if (obj) {
                        setting.visible = obj.visible;
                        setting.width = obj.width;
                        setting.index = obj.index;
                    }
                    auxColumnsSettings.push(setting);
                })

        } else {

            auxColumnsSettings = defaultColumnsSettings();
            auxColumns = [
                ..._tableColumns.filter(x => { return x.showAlways }),
                ..._tableColumns.filter(x => { return x.isDefault || x.isActionColumn })
            ];

        }

        setTableColumns(auxColumns);
        setColumnSettings(auxColumnsSettings.sort((a, b) => a.index < b.index ? -1 : 1));

        let doResearch = false;
        const aux: { [key: string]: any } = { ...filters };
        if (filters) {

            Object.keys(filters).forEach(element => {
                const f1 = _tableColumns.find(x => x.searchField == element);
                const f2 = auxColumns.find(x => x.searchField == element);
                if (f1 && !f2 && !!aux[element]) {
                    aux[element] = null;
                    doResearch = true;
                }
            });

            auxColumns.forEach(f2 => {
                if (!!f2.searchField && !aux[f2.searchField] && f2.defaultSearchValue) {
                    aux[f2.searchField] = f2.defaultSearchValue;
                    doResearch = true;
                }
            })
        }

        if (doResearch) {
            await onChangeFilters(aux);
        } else {
            if (refresh) {
                search();
            }
        }
    }

    const defaultColumnsSettings = () => {
        return _tableColumns.filter(x => !(x.isActionColumn || x.showAlways)).map((x, i) => mapTableColumnToColumnSettings(x, i));
    }

    const mapTableColumnToColumnSettings = (x: TableColumn, i: number) => {
        return {
            page: SEARCH_HISTORY_PAGES.LIST_PROJECTS.toString(),
            field: x.searchField ?? x.field,
            label: x.name,
            visible: x.isDefault ?? false,
            index: i,
            width: x.width,
            minWidth: x.minWidth
        }
    }

    const removeColumnsSettings = () => {
        applyColumnsSettings();
        saveColumnsSettings(defaultColumnsSettings(), true);
        setTblRerender(tblRerender + 1);
        return defaultColumnsSettings();
    }

    const saveColumnsSettings = async (settings: UserSettingsListingViewModel[], notification: boolean) => {
        try {
            const fixedColumns = tableColumns.filter(x => x.showAlways || x.isActionColumn).map(x => {
                return {
                    ...x,
                    visible: true,
                    label: x.name,
                    index: 0,
                    page: SEARCH_HISTORY_PAGES.LIST_PROJECTS.toString()
                }
            });
            if (notification) {
                Loading.show();
                await UserSettingsService.saveListingSettings({ listingSettings: [...settings, ...fixedColumns] });
                Loading.hide();
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            } else {
                await UserSettingsService.saveListingSettings({ listingSettings: [...settings, ...fixedColumns] });
            }
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const debouncedSaveColumnsSettings = useDebouncedCallback(
        // function
        (settings: UserSettingsListingViewModel[]) => saveColumnsSettings(settings, false),
        // delay in ms
        300
    );

    const setColumnsWidth = (obj: any) => {
        const cTableColumns = [...tableColumns];
        const cColumn = cTableColumns.find(x => x.field == obj.id);
        if (cColumn) {
            cColumn.width = obj.value;
        }

        const cTableSettings = [...columnsSettings];
        const cSettings = cTableSettings.find(x => x.field == obj.id);
        if (cSettings) {
            cSettings.width = obj.value;
        }

        setTableColumns(cTableColumns);
        setColumnSettings(cTableSettings);
        debouncedSaveColumnsSettings(cTableSettings);
    }

    const getCriteria = async () => {
        criteria.page = currentPage;
        criteria.userId = loggedUser?.id;
        criteria.groupId = loggedUser?.groupId;
        criteria.establishmentId = loggedUser?.establishmentId;
        criteria.orderBy = orderBy;
        criteria.orderColumn = orderColumn;
        criteria.itemsPerPage = itemsPerPage;

        if (filters) {
            updateTotalFilters(filters);
            criteria.identification = filters.identification;
            criteria.designation = filters.designation;
            criteria.statusId = filters.statusId;
            criteria.projectManageId = filters.projectManageId;
            criteria.priorityLevelId = filters.priorityLevelId;
            criteria.strategicAxisId = filters.strategicAxisId;
            criteria.departmentId = filters.departmentId;
            criteria.domainId = filters.domainId;
            criteria.searchEstablishment = filters.searchEstablishment;
            criteria.partnerId = filters.partnerId;
            criteria.productId = filters.productId;
            criteria.responsibleId = filters.responsibleId;
            criteria.typologyId = filters.typologyId;
            criteria.dateUpdatedProject = filters.dateUpdatedProject;
            criteria.userUpdatedProject = filters.userUpdatedProject;
            criteria.startBudgetings = filters.startBudgetings;
            criteria.endBudgetings = filters.endBudgetings;
            criteria.startDate = filters.startDate;
            criteria.endDate = filters.endDate;
            criteria.startCharges = filters.startCharges;
            criteria.endCharges = filters.endCharges;
            criteria.startRecurring = filters.startRecurring;
            criteria.endRecurring = filters.endRecurring;
            criteria.startInvestments = filters.startInvestments;
            criteria.endInvestments = filters.endInvestments;
            criteria.authorId = filters.authorId;
            criteria.startSubventionsExpected = filters.startSubventionsExpected;
            criteria.endSubventionsExpected = filters.endSubventionsExpected;
            criteria.startSubventionsObtained = filters.startSubventionsObtained;
            criteria.endSubventionsObtained = filters.endSubventionsObtained;
            criteria.responsibleDsiId = filters.responsibleDsiId;
            criteria.riskLevel = filters.riskLevel;
        }
        setCriteria({ ...criteria });
    };

    const showRemoveItemDialog = (item: ProjectViewModel) => {
        saveSearchHistory();
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await ProjectService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                if (projects.length > 1 || currentPage == 1) {
                    getProjects();
                } else {
                    setCurrentPage(currentPage - 1);
                }
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onChangeFilters = (filters: Filters) => {
        setCurrentPage(1);
        setFilters(filters);
        search();
    }

    const onClearFilters = () => {
        setItemsPerPage(10);
        const elTable = getTableElement();
        if (elTable) {
            elTable.scrollLeft = 0;
        }
        setTableScrollLeft(0);
    }

    const updateTotalFilters = (filters: Filters) => {
        const aux: { [key: string]: any } = { ...filters };
        setFiltersTotal(Object.keys(filters).filter(element => !!tableColumns.find(x => x.searchField == element || x.fields && x.fields.find(f => f == element)) && !!aux[element]).length);
    }

    const onItemsPerPageChange = (items: ItemsPerPage) => {
        setItemsPerPage(items);
        search();
    }

    const search = (page?: number) => {
        setCurrentPage(page ?? 1);
        setResearch(research + 1);
    }

    useEffect(() => {
        if (onInit) {
            getData();
            getUserSettings();
            setOnInit(false);
        }
    }, [onInit]);

    useEffect(() => {
        if (research > 0) {
            getProjects();
        }
    }, [research]);

    return (
        <ScreenTitle title={t('projects.title')} >
            {<ProjectsSidebar
                columns={[...columnsSettings]}
                applyColumnsSettings={(settings?: UserSettingsListingViewModel[]) => {
                    applyColumnsSettings(settings);
                    setTblRerender(tblRerender + 1);
                }}
                removeColumnsSettings={removeColumnsSettings}
                saveSettings={saveColumnsSettings}
            ></ProjectsSidebar>}
            <ScreenContainer>
                <div className={styles.toolbar}>
                    <Text preset="screenHeaderTitle" className={styles.toolbarTitle}>{t('projects.navbar.listing')}</Text>
                </div>
                <div className={styles.numberOfPages} >
                    <NumberPerPage key={'tableBoxSearch_' + tblRerender} itemsPerPage={itemsPerPage} onChangeItemsPerPage={onItemsPerPageChange}></NumberPerPage>
                    {userCanWrite && <Button preset="primary" className={styles.toolbarButton} onClick={goToNewProject}>{t('common.new')}</Button>}
                    <div data-tip={t('projects.export_excel')} data-for='global'>
                        <Button onClick={() => exportData()} preset="secondary" onlyIcon={true} className={styles.toolbarButton}>
                            <FaShareSquare className={styles.toolbarButtonIcon} />
                            <ReactTooltip id='global' type='info' getContent={(dataTip: string) => <span>{dataTip}</span>}></ReactTooltip>
                        </Button>
                    </div>
                </div>
                {tblRerender > 0 && filters && <TableProjectBox
                    status={status}
                    projectManagers={projectManagers}
                    priorityLevels={priorityLevels}
                    strategicAxes={strategicAxes}
                    departments={departments}
                    domains={domains}
                    partners={partners}
                    products={products}
                    responsibles={responsibles}
                    typologies={typologies}
                    authors={authors}
                    users={users}
                    responsiblesDsi={responsiblesDsi}
                    onClearFilters={onClearFilters}
                    key={'tableBox_' + tblRerender}
                    className={styles.projectBox}
                    columns={tableColumns}
                    rows={projects}
                    onRowClick={row => goToProject(row as ProjectViewModel, 'details')}
                    onOrderList={onOrderList}
                    filters={filters}
                    onFilter={onChangeFilters}
                    setColumnsWidth={setColumnsWidth}
                    filtersTotal={filtersTotal}
                    orderBy={orderBy}
                    orderColumn={orderColumn}
                />}
                <QuestionYesNo message={t('common.messages.remove_record')}
                    isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />
                {totalItems > 0 && <div className={styles.paginationContainer}>
                    <PaginationTextInfo className={styles.paginationInfo} itemName={t('projects.items')} items={projects.length} totalItems={totalItems} />
                    <Pagination currentPage={currentPage} onChange={onPageChange} totalItems={totalItems} itemsPerPage={itemsPerPage} />
                </div>}
            </ScreenContainer>
        </ScreenTitle >
    );
}

export default ProjectsScreen;
