import React, { useState } from 'react';
import ListProjectResponsibleDsiScreen from '../../projectResponsiblesDsi/list/ListProjectResponsiblesDsiScreen';
import ProjectResponsibleDsiScreen from '../../projectResponsiblesDsi/ProjectResponsibleDsiScreen';
import { ProjectResponsibleDsiViewModel } from '../../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import { GROUP_PARAMS_TYPE } from '../../../Config';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_PROJECT_RESPONSIBLES_SDI,
    PROJECT_RESPONSIBLES_SDI
}

const ParametersProjectResponsibleDsiScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_PROJECT_RESPONSIBLES_SDI);
    const [currentProjectResponsibleDsi, setCurrentProjectResponsibleDsi] = useState<ProjectResponsibleDsiViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_PROJECT_RESPONSIBLES_SDI && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.PROJECT_RESPONSIBLES_DSI}/> }
            { screen === ScreenType.LIST_PROJECT_RESPONSIBLES_SDI && <ListProjectResponsibleDsiScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewProjectResponsibleDsi={() => {
                    setIsDetails(false);
                    setCurrentProjectResponsibleDsi(null);
                    setScreen(ScreenType.PROJECT_RESPONSIBLES_SDI)
                }}
                onClickItem={(item: ProjectResponsibleDsiViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentProjectResponsibleDsi(item);
                    setScreen(ScreenType.PROJECT_RESPONSIBLES_SDI);
                }}
            ></ListProjectResponsibleDsiScreen>}
            { screen === ScreenType.PROJECT_RESPONSIBLES_SDI && <ProjectResponsibleDsiScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                projectResponsiblesDsiId={currentProjectResponsibleDsi?.id}
                afterSave={() => {
                    setCurrentProjectResponsibleDsi(null);
                    setScreen(ScreenType.LIST_PROJECT_RESPONSIBLES_SDI);
                }}></ProjectResponsibleDsiScreen>}
        </div>

    );
}

export default ParametersProjectResponsibleDsiScreen;
