import { ScoreType } from '../../scores/enums/ScoreType';
export interface ProjectViewModel {
    id?: string;
    identification: string;
    designation: string;
    projectVersion: string;
    groupId: string;
    priorityLevelId: string;
    authorId: string;
    statusId: string;
    statusName?: string;
    domainId: string;
    strategicAxes: ProjectStrategicAxesViewModel[];
    products: ProjectProductViewModel[];
    concernedEstablishments: ProjectConcernedEstablishmentViewModel[];
    selectAllConcernedEstablishments: boolean;
    departmentsInvolved: ProjectDepartmentViewModel[];
    investmentTypes: ProjectInvestmentTypeViewModel[];
    internalAllocationTypes: ProjectAllocationTypeViewModel[];
    investmentTotals: ProjectInvestmentTotalsViewModel[];
    internalAllocationTotals: ProjectAllocationTotalsViewModel[];
    startDate: Date;
    endDate: Date;
    investimentsUnitId: string;
    investimentsUnit: string;
    internalAllocationsUnitId: string;
    internalAllocationsUnit: string;
    successIndicators: ProjectSuccessIndicatorViewModel[];
    evaluationMethodologies: ProjectEvaluationMethodologyViewModel[];
    phases: ProjectPhaseViewModel[];
    deadlines: string;
    relatedProjects: RelatedProjectViewModel[];
    sourceId: string;
    projectManagerId: string;
    responsibles: ProjectProjectResponsibleViewModel[];
    implementationMethods: string;
    observationsImpactsOrganization: string;
    technologiesSolutionsDescription: string;
    scores: ProjectScoreViewModel[];
    totalOperatingInvestments: number;
    totalOperatingInvestmentsComment: string;
    totalCostComment: string;
    qualityQuestions: ProjectQualityQuestionViewModel[];
    budgetings: ProjectBudgetingViewModel[];
    subventions: ProjectSubventionViewModel[];
    contextDiagnosis: string;
    projectObjectivesAndChallenges: string;
    targetPopulations: string;
    expectedFeatures: string;
    risksOfNotDoing: string;
    vigilancePoints: string;
    concernedPartners: ProjectConcernedPartnerViewModel[];
    typologyId: string;
    updatedDate?: Date | null;
    responsiblesDsi: ProjectProjectResponsibleDsiViewModel[];
    riskLevel?: RiskLevel;
    riskDescription?: string;
}

export interface ProjectResponsibleViewModel {
    responsibleId: string;
    name: string;
}

export interface RelatedProjectViewModel {
    relatedProjectId: string;
    name: string;
}

export interface ProjectStrategicAxesViewModel {
    strategicAxisId: string;
    name: string;
}

export interface ProjectProductViewModel {
    productId: string;
    name: string;
}

export interface ProjectDepartmentViewModel {
    departmentId: string;
    name: string;
}

export interface ProjectConcernedEstablishmentViewModel {
    establishmentId: string;
    name: string;
    poles?: ProjectConcernedEstablishmentPoleViewModel[];
}

export interface ProjectConcernedEstablishmentPoleViewModel {
    name: string;
    poleId: string;
}

export interface ProjectInvestmentTypeViewModel {
    name: string;
    iconSvg: string;
    investmentTypeId: string;
    totalComment: string;
    dates: ProjectInvestmentTypeDateViewModel[];
    establishments: ProjectInvestmentTypeEstablishmentViewModel[];
}

export interface ProjectInvestmentTypeDateViewModel {
    total: number;
    date: Date;
}

export interface ProjectInvestmentTypeEstablishmentViewModel {
    establishmentId: string;
    establishmentName: string;
    percentage: number
    total: number;
    poles: ProjectInvestmentTypeEstablishmentPoleViewModel[];
}

export interface ProjectInvestmentTypeEstablishmentPoleViewModel {
    poleId: string;
    poleName: string;
    percentage: number;
}

export enum DateMode {
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export interface ProjectInvestmentTotalsViewModel {
    date: Date;
    dateMode: string;
    dateFormat: string;
    total: number;
    totalFinal: number;
    report: number;
    comment?: string | null;
}

export interface ProjectSuccessIndicatorViewModel {
    successIndicatorId: string;
    name: string;
}

export interface ProjectEvaluationMethodologyViewModel {
    evaluationMethodologyId: string;
}

export interface ProjectPhaseViewModel {
    guid: string;
    name: string;
    order: number;
    percentageAchievement: number;
    dates: ProjectPhaseDateViewModel[];
    countMonths?: number;
}

export interface ProjectPhaseDateViewModel {
    date: Date;
    dateMode: DateMode;
    dateFormat: string;
    periods?: ProjectPhaseDatePeriodViewModel[]; // Used when saving
    periodsItems?: ProjectPhaseDatePeriodItemViewModel[]; // Used in frontend
}

export interface ProjectPhaseDatePeriodViewModel {
    dateStart: Date;
    dateEnd: Date;
    provisionalSchedules: ProjectPhaseDatePeriodProvisionalScheduleViewModel[] | undefined;
}

export interface ProjectPhaseDatePeriodProvisionalScheduleViewModel {
    id?: string;
    projectResponsibleDsiId: string;
    projectResponsibleDsiName: string;
    date: Date;
    estimatedTime?: number;
}

export interface ProjectPhaseDatePeriodItemViewModel {
    date: Date;
    checked: boolean;
    provisionalSchedules?: ProjectPhaseDatePeriodProvisionalScheduleViewModel[] ;
}

export interface ProjectAllocationTypeViewModel {
    name: string;
    iconSvg: string;
    internalAllocationTypeId: string;
    dates: ProjectAllocationTypeDateViewModel[];
    establishments: ProjectAllocationTypeEstablishmentViewModel[];
}

export interface ProjectAllocationTypeDateViewModel {
    total: number;
    date: Date;
}

export interface ProjectAllocationTypeEstablishmentViewModel {
    establishmentId: string;
    establishmentName: string;
    percentage: number
    total: number;
    poles: ProjectAllocationTypeEstablishmentPoleViewModel[];
}

export interface ProjectAllocationTypeEstablishmentPoleViewModel {
    poleId: string;
    poleName: string;
    percentage: number;
}

export interface ProjectAllocationTotalsViewModel {
    date: Date;
    dateMode: string;
    dateFormat: string;
    total: number;
}

export interface ProjectProjectResponsibleViewModel {
    projectResponsibleId: string;
    name: string;
}

export interface ProjectScoreViewModel {
    scoreId: string;
    name: string;
    value: number;
    type?: ScoreType | null;
    isNew?: boolean;
}

export interface ProjectQualityQuestionViewModel {
    qualityQuestionId: string;
    name: string;
    value?: number;
}

export interface ProjectBudgetingViewModel {
    name: string;
    value?: number;
}

export interface ProjectSubventionViewModel {
    name: string;
    typeSubvention: string;
    expectedValue: number;
    realValue: number;
}

export interface ProjectConcernedPartnerViewModel {
    partnerId: string;
    name: string;
}

export interface ProjectProjectResponsibleDsiViewModel {
    projectResponsibleDsiId: string;
    name: string;
}

export enum RiskLevel {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    NONE = 'NONE'
}