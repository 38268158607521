import { PagedSearchCriteria } from '../../common/types/PagedSearchCriteria';

export interface ProjectsSearchCriteria  extends PagedSearchCriteria {
    userId?: string; 
    groupId?: string;
    establishmentId?: string;
    columnsExport?: any;

    identification?: string;
    designation?: string;
    statusId?: string[];
    projectManageId?: string[];
    priorityLevelId?: string[];
    strategicAxisId?: string[];
    departmentId?: string[];
    domainId?: string[];
    searchEstablishment?: string;
    partnerId?: string[];
    productId?: string[];
    responsibleId?: string[];
    typologyId?: string[];
    dateUpdatedProject?: Date;
    userUpdatedProject?: string[];
    startBudgetings?: number;
    endBudgetings?: number;
    startDate?: Date | null;
    endDate?: Date | null;
    startCharges?: number;
    endCharges?: number;
    startRecurring?: number;
    endRecurring?: number;
    startInvestments?: number;
    endInvestments?: number;
    authorId?: string[];
    startSubventionsExpected?: number;
    endSubventionsExpected?: number;
    startSubventionsObtained?: number;
    endSubventionsObtained?: number;
    responsibleDsiId?: string[];
    riskLevel?: string[];
    
    scrollLeft?: number;
}

export enum ColumnField {
    IDENTIFICATION = 'identification',
    DESIGNATION = 'designation',
    PROJECT_MANAGER = 'workResponsible',
    PRIORITY_LEVEL = 'priorityLevel',
    STATUS = 'status',
    STRATEGIC_AXES = 'strategicAxes',
    DEPARTMENTS = 'departmentsInvolved',
    DOMAIN = 'domainProject',
    ESTABLISHMENTS = 'concernedEstablishments',
    PARTNERS = 'concernedPartners',
    PRODUCTS = 'products',
    RESPONSIBLES = 'responsibles',
    TYPOLOGY = 'typologyProject',
    DATE_UPDATED_PROJECT = 'updatedDateProject',
    USER_UPDATED_PROJECT = 'userUpdatedProject',
    START_END_DATE = 'startEndDate',
    BUDGETINGS = 'budgetings',
    TOTAL_CHARGES = 'totalCharges',
    TOTAL_RECURRING = 'totalRecurring',
    INVESTIMENTS = 'investments',
    AUTHOR = 'author',
    SUBVENTIONS_EXPECTED = 'subventionsExpected',
    SUBVENTIONS_OBTAINED = 'subventionsObtained',
    RESPONSIBLES_DSI = 'responsiblesDsi',
    COLAB = 'collaborator',
    PLANNED_REALIZED = 'planned_realized',
    RISK = 'riskLevel'
}

export enum SearchType {
    TEXT = 'text',
    SELECT = 'select',
    MULTISELECT = 'multiselect',
    DATE = 'date',
    RANGE_DATE = 'rangeDate',
    RANGE_VALUE = 'rangeValue',
    LIGHTS = 'lights'
}

export enum SearchField {
    IDENTIFICATION = 'identification',
    DESIGNATION = 'designation',
    PROJECT_MANAGER = 'projectManageId',
    PRIORITY_LEVEL = 'priorityLevelId',
    STATUS = 'statusId',
    STRATEGIC_AXIS = 'strategicAxisId',
    DEPARTMENT = 'departmentId',
    DOMAIN = 'domainId',
    SEARCH_ESTABLISHMENT = 'searchEstablishment',
    PARTNER = 'partnerId',
    PRODUCT = 'productId',
    RESPONSIBLE = 'responsibleId',
    TYPOLOGY = 'typologyId',
    DATE_UPDATED_PROJECT = 'dateUpdatedProject',
    USER_UPDATED_PROJECT = 'userUpdatedProject',
    START_END_DATE = 'startEndDate',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    BUDGETINGS = 'budgetings',
    START_BUDGETINGS = 'startBudgetings',
    END_BUDGETINGS = 'endBudgetings',
    TOTAL_CHARGES = 'totalCharges',
    START_CHARGES = 'startCharges',
    END_CHARGES = 'endCharges',
    TOTAL_RECURRING = 'totalRecurring',
    START_RECURRING = 'startRecurring',
    END_RECURRING = 'endRecurring',
    INVESTIMENTS = 'investments',
    START_INVESTIMENTS = 'startInvestments',
    END_INVESTIMENTS = 'endInvestments',
    AUTHOR = 'authorId',
    SUBVENTIONS_EXPECTED = 'subventionsExpected',
    START_SUBVENTIONS_EXPECTED = 'startSubventionsExpected',
    END_SUBVENTIONS_EXPECTED = 'endSubventionsExpected',
    SUBVENTIONS_OBTAINED = 'subventionsObtained',
    START_SUBVENTIONS_OBTAINED = 'startSubventionsObtained',
    END_SUBVENTIONS_OBTAINED = 'endSubventionsObtained',
    RESPONSIBLE_DSI = 'responsibleDsiId',
    PROJECT_ESPONSIBLE_DSI = 'responsibleDsiId',
    RISK = 'riskLevel'
}