/* eslint-disable react/jsx-key */
import React, { CSSProperties, ReactElement } from 'react';
import styles from './ListingTable.module.scss';
import { Property } from 'csstype'
import { useTranslation } from 'react-i18next';
import Badge from '../../../common/components/badge/Badge';

export interface ListingTableCell {
  column: ListingTableColumn;
  row: ListingTableRow;
}

export interface ListingTableColumn {
  field: string;
  name?: string;
  renderCell?: (cell: ListingTableCell) => ReactElement;
  cellAlignment?: Property.TextAlign;
  cellStyle?: CSSProperties;
  columnCellAlignment?: Property.TextAlign;
  width?: 'fill' | number | string;
  preventClick?: boolean;
  type?: string;
}

export interface ListingTableRow {
  [field: string]: any;
}

interface Props {
  columns: ListingTableColumn[];
  rows: ListingTableRow[];
  allowHover?: boolean;
  onRowClick?: (row: ListingTableRow) => void;
  showEmptyTable?: boolean;
}

const ListingTable: React.FunctionComponent<Props> = ({ columns, rows, allowHover, showEmptyTable, onRowClick }: Props) => {

  const { t } = useTranslation();

  const getCellWidthStyle = (col: ListingTableColumn) => {
    if (!col.width) {
      return {};
    }

    if (col.width === 'fill') {
      return { flex: 1 }
    }

    if (typeof col.width === 'string') {
      return { width: col.width }
    }

    return {
      width: ((col.width * 100) / 20) + '%',
    };
  }

  const getColumnCellStyle = (col: ListingTableColumn) => {
    return {
      textAlign: col.columnCellAlignment || 'left',
      ...getCellWidthStyle(col),
    }
  }

  const getRowCellStyle = (col: ListingTableColumn) => {
    return {
      textAlign: col.cellAlignment || 'left',
      ...getCellWidthStyle(col),
      ...(col.cellStyle || {})
    }
  }

  const getRowCellValue = (row: ListingTableRow, col: ListingTableColumn) => {
    if (col.renderCell) {
      return col.renderCell({ row, column: col });
    }

    const value = row[col.field];

    return value;
  }

  return (
    <div>
      {(Boolean(rows.length > 0) || showEmptyTable) && <div>
        <div className={styles.columns}>
          {columns.map((col, colIndex) => (
            <div key={`col-${colIndex}`} className={styles.columnCell} style={getColumnCellStyle(col)}>
              {col.name}
            </div>
          ))}
        </div>
        <div className={`${styles.columns} ${styles.emptyColumn}`}></div>
      </div>}
      <div className={styles.rows}>
        {rows.map((row, rowIndex) => (
          <div key={`row-${rowIndex}`} className={`${styles.row} ${allowHover && !row.isTotalLine && styles.rowHover} ${rowIndex == 0 ? styles.rowBorderTop : ''} ${rowIndex != rows.length && !row.isTotalLine ? styles.rowBorderBottom : ''} `}>
            {columns.map((col, colIndex) => (
              <div key={`row-cell-${colIndex}`} className={styles.rowCell}
                onClick={() => onRowClick && !col.preventClick && !row.isTotalLine && onRowClick(row)}
                style={getRowCellStyle(col)}>
                {!col.type && col.type !== 'status' && getRowCellValue(row, col)}
                {col.type && col.type === 'status' && <Badge color={getRowCellValue(row, col)}>{getRowCellValue(row, columns[0])}</Badge>}
              </div>
            ))}
          </div>
        ))}
        {Boolean(rows.length <= 0) && <div className={`${styles.row} ${styles.noResultsText}`}>{t('common.no_results')}</div>}
      </div>
    </div>
  );
}


export default ListingTable;