import React, { useState } from 'react';
import { ScoreViewModel } from '../../../api/scores/models/ScoreViewModel';
import ScoreScreen from '../../scores/ScoreScreen';
import ListScoresScreen from '../../scores/list/ListScoresScreen';
import { GROUP_PARAMS_TYPE } from '../../../Config';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';


type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_PROJECT_SCORES,
    PROJECT_SCORE
}

const ParametersScoresScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_PROJECT_SCORES);
    const [currentScore, setCurrentScore] = useState<ScoreViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_PROJECT_SCORES && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.SCORES}/> }
            { screen === ScreenType.LIST_PROJECT_SCORES && <ListScoresScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewScore={() => {
                    setIsDetails(false);
                    setCurrentScore(null);
                    setScreen(ScreenType.PROJECT_SCORE)
                }}
                onClickItem={(item: ScoreViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentScore(item);
                    setScreen(ScreenType.PROJECT_SCORE);
                }}
            ></ListScoresScreen>}
            { screen === ScreenType.PROJECT_SCORE && <ScoreScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                scoreId={currentScore?.id}
                afterSave={() => {
                    setCurrentScore(null);
                    setScreen(ScreenType.LIST_PROJECT_SCORES);
                }}></ScoreScreen>}
        </div>

    );
}

export default ParametersScoresScreen;
