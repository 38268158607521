/* eslint-disable react/display-name */
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale, ReactDatePickerProps as DateProps } from 'react-datepicker';
import { fr, pt, enGB  } from 'date-fns/locale';
import { Portal } from 'react-overlays';

registerLocale('fr', fr)
registerLocale('pt', pt)
registerLocale('enGB', enGB)

type Props = {
} & DateProps;

const CalendarContainer = ({ children } :any ) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const DateTimePicker = React.forwardRef<DatePicker, Props>(({ ...props  }, ref) => {
  return (
      <DatePicker
        {...props}
        ref={ref}
        popperContainer={CalendarContainer}
      />
    );
});

export default DateTimePicker;