import React, { useState } from 'react';

import ListSourcesScreen from '../../sources/list/ListSourcesScreen';
import SourcesScreen from '../../sources/SourcesScreen';
import { SourceViewModel } from '../../../api/sources/models/SourceViewModel';
import { GROUP_PARAMS_TYPE } from '../../../Config';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_SOURCES,
    SOURCE
}

const ParametersSourcesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_SOURCES);
    const [currentSource, setCurrentSource] = useState<SourceViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_SOURCES && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.SOURCES}/> }
            { screen === ScreenType.LIST_SOURCES && <ListSourcesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewSource={() => {
                    setIsDetails(false);
                    setCurrentSource(null);
                    setScreen(ScreenType.SOURCE)
                }}
                onClickItem={(item: SourceViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentSource(item);
                    setScreen(ScreenType.SOURCE);
                }}
            ></ListSourcesScreen>}
            { screen === ScreenType.SOURCE && <SourcesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                sourceId={currentSource?.id}
                afterSave={() => {
                    setCurrentSource(null);
                    setScreen(ScreenType.LIST_SOURCES);
                }}></SourcesScreen>}
        </div>

    );
}

export default ParametersSourcesScreen;
