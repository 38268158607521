/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import LibSelect, { Props as SelectProps, components } from 'react-select';
import colors from './../../../styles/export/colors.module.scss';
import styles from './Select.module.scss';


export type Props = {
    isDisabled: boolean;
    roundCorners?: boolean;
    width?: string | number;
    divider?: boolean;
    height?: string;
    showOnlyFirstSelected?: boolean;
} & SelectProps;


const Select = React.forwardRef<LibSelect, Props>(({ roundCorners, width, ...props  }: Props, ref) => {
    const { t } = useTranslation();
    let countMultiSelectItems = 0;


    const CustomMultiValueContainer = (props: any) => {
        const label = props.data.label;
        const allSelected = props.selectProps.value;
        const index = allSelected.findIndex ? allSelected.findIndex((selected: any) => selected.label === label) : 0;
        // const isLastSelected = index === allSelected.length - 1;
        const isFirstSelected = index === 0;
        // const countItems = props.selectProps.value.length;

        countMultiSelectItems = props.selectProps.value.length - 1;

        return (
            <Fragment>
                {(isFirstSelected 
                // || isLastSelected
                ) && 
                    <components.MultiValueContainer {...props}>
                        <span className={styles.multiselect}>
                            { isFirstSelected && label }
                        </span>
                        

                        {/* { countItems > 1 && isLastSelected &&
                            <span className={styles.multiselect}>+ {countItems - 1}</span>
                        } */}
                    </components.MultiValueContainer>
                }
            </Fragment>
        );
    }


    const CustomIndicatorsContainer = (props: any) => {
        return (
            <Fragment>
                { countMultiSelectItems > 0 &&
                    <span className={styles.multiselect}>+ {countMultiSelectItems}</span>
                }
                <components.IndicatorsContainer {...props}>
                </components.IndicatorsContainer>
                
            </Fragment>
        );
    }


    return (
        <LibSelect
            {...props}
            components={ props.showOnlyFirstSelected ? { MultiValueContainer:  CustomMultiValueContainer, IndicatorsContainer: CustomIndicatorsContainer } : {} }
            hideSelectedOptions={ false }
            ref={ref}
            noOptionsMessage={() => t('common.no_options')}
            styles={{
                menuPortal: base => ({
                    ...base,
                    zIndex: 8,
                }),
                menu: base => ({
                    ...base,
                    zIndex: 3,
                }),
                control: (base, state) => ({
                    ...base,
                    backgroundColor: props.isDisabled ? colors.whisper : colors.white,
                    borderRadius: roundCorners ? 30 : 2,
                    border: state.isFocused ? `1px solid ${colors.primary}` : `1px solid ${colors.mercury}`,
                    boxShadow: 'none',
                    ':hover': {
                        borderColor: state.isFocused ? colors.primary : colors.mercury,
                    },
                    width,
                    height: props.height ? props.height : undefined,
                    minHeight: props.height ? props.height : undefined
                }),
                placeholder: base => ({
                    ...base,
                    color: colors.frenchGray,
                    opacity: 0.54
                }),
                valueContainer: (base, state) => ({
                    ...base,
                    padding: '6px 1rem',
                    
                    ...(props?.showOnlyFirstSelected ? { paddingRight: '0' } : {}),

                    // eslint-disable-next-line react/prop-types
                    height: props.height ? props.height : undefined,
                    // eslint-disable-next-line react/prop-types
                    minHeight: props.height ? props.height : undefined,
                    ...(props?.styles?.valueContainer ? props?.styles?.valueContainer(base, state) : {})
                }),
                option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? colors.primary : colors.white,
                    ':hover': {
                        backgroundColor: colors.lightGrayishBlue,
                    },
                    // eslint-disable-next-line react/prop-types
                    height: props.height ? props.height : undefined,
                    // eslint-disable-next-line react/prop-types
                    minHeight: props.height ? props.height : undefined
                }),
                singleValue: base => ({
                    ...base,
                    color: colors.lightblack,
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal'
                }),
                multiValue: base => ({
                    ...base,
                    color: colors.lightblack,
                    ...(props?.showOnlyFirstSelected ? { backgroundColor: colors.white, ':first-child': { maxWidth: '80px' } } : { backgroundColor: colors.solitude }),
                }),
                multiValueLabel: base => ({
                    ...base,
                }),
                multiValueRemove: base => ({
                    ...base,
                    backgroundColor: colors.solitude,
                    color: colors.primary,
                }),
                indicatorSeparator: base => ({
                    ...base,
                    // eslint-disable-next-line react/prop-types
                    display: props.divider ? 'block' : 'none',
                }),
                clearIndicator: base => ({
                    ...base,
                   paddingLeft:0,
                   paddingRight:0,
                }),
                dropdownIndicator: base => ({
                    ...base,
                   paddingLeft:0,
                })
            }}
        />
    );
});

export default Select;
