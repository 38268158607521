import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Button from '../../common/components/button/Button';
import FormItem from '../../common/components/formItem/FormItem';
import Input from '../../common/components/input/Input';
import InputError from '../../common/components/inputError/InputError';
import Label from '../../common/components/label/Label';
import Loading from '../../common/services/Loading';
import styles from './ProjectResponsibleDsiScreen.module.scss';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import { ProjectResponsibleDsiViewModel } from '../../api/projectResponsiblesDsi/models/ProjectResponsibleDsiViewModel';
import ProjectResponsibleDsiService from '../../api/projectResponsiblesDsi/ProjectResponsiblesDsiService';
import { ProjectResponsibleDsiPlanningHours, ProjectResponsibleDsiPlanningViewModel } from '../../api/projectResponsibleDsiPlanning/models/ProjectResponsibleDsiPlanningViewModel';
import TableRow from './components/tableRow/TableRow';
import { RiAddCircleLine } from 'react-icons/ri'
import colors from '../../styles/export/colors.module.scss';
import DateFormat from '../../common/components/dateFormat/dateFormat';
import { DATE_FORMAT_YEAR, POLICIES } from '../../Config';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { useSelector } from 'react-redux';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    afterSave: () => void;
    projectResponsiblesDsiId?: string;
    isDetails: boolean;
};

export type ProjectResponsibleDsiForm = {
    name: string;
    planning: ProjectResponsibleDsiPlanningViewModel[];
}

enum ScreenType {
    NEW,
    EDIT,
    DETAILS
}

const ProjectResponsibleDsiScreen: React.FC<Props> = ({ projectResponsiblesDsiId, groupId, afterSave, isDetails, userCanRead, userCanWrite }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const form = useForm<ProjectResponsibleDsiForm>();
    const { register, handleSubmit, errors, setValue } = form;
    const [screenMode, setScreenMode] = useState<ScreenType>(isDetails && !!projectResponsiblesDsiId ? ScreenType.DETAILS : !!projectResponsiblesDsiId ? ScreenType.EDIT : ScreenType.NEW);
    const [itemToRemove, setItemToRemove] = useState<ProjectResponsibleDsiViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const [usedYears, setUsedYears] = useState<Date[]>([]);
    const monthArray = new Array(12).fill({});

    const [description, setDescription] = useState<string>();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userCanReadTimes = !!loggedUser?.policies.find(x => x == POLICIES.COLLABORATORS_TIMES_READ || x == POLICIES.COLLABORATORS_TIMES_WRITE);
    const userCanWriteTimes = !!loggedUser?.policies.find(x => x == POLICIES.COLLABORATORS_TIMES_WRITE);

    const onCancel = () => {
        afterSave();
    }

    const onSubmit = async (formData: ProjectResponsibleDsiForm) => {
        try {
            setIsSubmitted(true);
            if (!formData.name) {
                return;
            }

            if (planning.find(x => !x.year)) return;

            Loading.show();

            planning.map((planning: ProjectResponsibleDsiPlanningViewModel) => {
                planning.planningHours = planning.planningHours.filter(x => (!!x.timeSpent || x.timeSpent === 0));
                return planning;
            })

            if (groupId) {
                await ProjectResponsibleDsiService.save({
                    id: projectResponsiblesDsiId,
                    name: formData.name,
                    groupId,
                    planning: planning,
                    description
                });
            }

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            afterSave();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onError = async () => {
        console.log('onError')
        setIsSubmitted(true);
    }

    const getDomain = async () => {
        try {
            if (projectResponsiblesDsiId) {
                Loading.show();

                const result = await ProjectResponsibleDsiService.getProjectResponsibleDsiById(projectResponsiblesDsiId);
                setValue('name', result?.name);
                setDescription(result.description ?? '');

                const plan = result?.planning ?? [];
                plan.map((x: ProjectResponsibleDsiPlanningViewModel) => {
                    x.planningHours = monthArray.map((_: any, index: number) => {
                        const item = x.planningHours.find(z => z.month === (index + 1));
                        return item ?? {
                            month: index + 1,
                            timeSpent: undefined,
                        };
                    });
                    return x;
                });
                setPlannig(plan);
                setUsedYears(plan.filter(x => !!x.year).map(x => (x.year as Date)));

                Loading.hide();
            }
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: ProjectResponsibleDsiViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await ProjectResponsibleDsiService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onCancelEdit = () => {
        if (screenMode === ScreenType.EDIT && !!isDetails) {
            setScreenMode(ScreenType.DETAILS);
            getDomain();
        } else {
            onCancel()
        }
    }

    useEffect(() => {
        if (userCanRead) {
            if (!!projectResponsiblesDsiId) {
                getDomain();
            }
        }
    }, [projectResponsiblesDsiId]);

    useEffect(() => {
        getDomain();
    }, [screenMode]);

    const [planning, setPlannig] = useState<ProjectResponsibleDsiPlanningViewModel[]>([]);

    const append = () => {
        const months: ProjectResponsibleDsiPlanningHours[] = monthArray.map((_: any, index: number) => ({
            month: index + 1,
            timeSpent: undefined,
        }));

        const model: ProjectResponsibleDsiPlanningViewModel = {
            year: undefined,
            planningHours: months,
        };

        setPlannig([...planning, model]);
    }

    const onRemove = (index: number) => {
        const filtered: ProjectResponsibleDsiPlanningViewModel[] = [];

        planning.forEach((value: ProjectResponsibleDsiPlanningViewModel, i: number) => {
            if (i !== index) filtered.push(value);
        });
        setPlannig(filtered);
        setUsedYears(filtered.filter(x => !!x.year).map(x => (x.year as Date)));
    }

    const onChange = (elem: ProjectResponsibleDsiPlanningViewModel, index: number) => {
        const filtered: ProjectResponsibleDsiPlanningViewModel[] = [];

        planning.forEach((value: ProjectResponsibleDsiPlanningViewModel, i: number) => {
            filtered.push((i !== index) ? value : elem);
        });

        setPlannig(filtered);
        setUsedYears(filtered.filter(x => !!x.year).map(x => (x.year as Date)));
    }

    const renderHeader = (): JSX.Element => {
        return <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div className={styles.tableHeader}></div>
            {monthArray.map((_: any, index: number) => <div key={`header-${index}`} className={styles.tableHeader}>{t(`common.months_abbreviation_mini.${index + 1}` as any)}</div>)}
            <div className={styles.tableHeaderAdd}>
                {userCanWriteTimes && screenMode !== ScreenType.DETAILS && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ cursor: 'pointer' }} onClick={append}>
                        <RiAddCircleLine size={25} fill={colors.primary} />
                    </div>
                </div>}
            </div>
        </div>
    }

    const renderRowDetails = (elem: ProjectResponsibleDsiPlanningViewModel): JSX.Element => {
        return <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div className={`${styles.tableRow} ${styles.firstCol}`}>
                {elem.year && <DateFormat value={elem.year} format={DATE_FORMAT_YEAR} />}
            </div>
            {monthArray.map((_: any, index: number) => {
                const e: ProjectResponsibleDsiPlanningHours | undefined = elem.planningHours.find(x => x.month === (index + 1));
                return <div key={`header-${index}`} className={`${styles.tableRow}`}>
                    {e && e.timeSpent && <>
                        <span>{e.timeSpent}</span>
                        <span>h</span>
                    </>}
                </div>
            })}
            <div className={styles.tableHeaderAdd}>
            </div>
        </div>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} className={styles.formContent} style={{ minHeight: '511px', position: 'relative' }}>
            <div className={styles.formLine}>
                <FormItem className={`${styles.formItem} ${styles.floatLeft}`}>
                    <Label className={styles.bold}>{t('common.name')} {screenMode === ScreenType.DETAILS ? '' : '*'}</Label>
                    <Input name="name" placeholder={t('common.name')} ref={register({ required: true, maxLength: 255 })} disabled={screenMode === ScreenType.DETAILS} maxLength={255} />
                    <InputError error={errors.name} />
                </FormItem>
                <FormItem className={styles.formItem}>
                    <Label className={styles.bold}>{t('common.description')}</Label>
                    <TextareaAutosize
                        style={{ resize: 'none' }}
                        name='description'
                        value={description}
                        disabled={screenMode === ScreenType.DETAILS}
                        className={`${styles.input} ${styles.inputDefault} ${screenMode === ScreenType.DETAILS ? styles.inputDisabled : ''}`}
                        onChange={e => {
                            setDescription(e.currentTarget.value);
                        }}
                        rows={2}
                    />
                </FormItem>
            </div>
            { userCanReadTimes && <div key={`table-${planning.length}-${isSubmitted}`} style={{ minHeight: '511px', position: 'relative', padding: '0 1rem 1rem 1rem' }}>
                <Label className={styles.bold}>{t('projects.table.responsible_dsi_planning')}</Label>

                {renderHeader()}
                {planning.map((elem: ProjectResponsibleDsiPlanningViewModel, index: number) => {
                    return <div key={`container-${index}`}>
                        {screenMode === ScreenType.DETAILS
                            ? renderRowDetails(elem)
                            : <TableRow
                                key={`row-${planning.length}-${isSubmitted}`}
                                data={elem}
                                parentIndex={index}
                                onRemove={onRemove}
                                onChange={onChange}
                                submitted={isSubmitted}
                                usedYears={usedYears}
                                userCanWriteTimes={userCanWriteTimes}
                            />}
                    </div>
                })}
            </div>}
            {screenMode !== ScreenType.DETAILS &&
                <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    <Button type="button" text={t('common.cancel')} preset={'secondary'} size={'small'} onClick={onCancelEdit} />
                    {userCanWrite && <Button type="submit" text={t('common.save')} size={'small'} />}
                </div>}
            {screenMode === ScreenType.DETAILS &&
                <div className={styles.buttonsFooter} style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    <Button type="button" text={t('common.back')} preset={'secondary'} size={'small'} onClick={() => onCancel()} />
                    {userCanWrite && <Button type="button" text={t('common.remove')} preset={'danger'} size={'small'} onClick={() => showRemoveItemDialog({ id: projectResponsiblesDsiId } as ProjectResponsibleDsiViewModel)} />}
                    <QuestionYesNo message={t('common.messages.remove_record')}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                    {userCanWrite && <Button type="button" text={t('common.edit')} onClick={() => { setScreenMode(ScreenType.EDIT) }} size={'small'} />}
                </div>}
        </form>
    );
}

export default ProjectResponsibleDsiScreen;
