import React, { useState } from 'react';
import { EvaluationMethodologyViewModel } from '../../../api/evaluationMethodologies/models/EvaluationMethodologyViewModel';
import { GROUP_PARAMS_TYPE } from '../../../Config';
import EvaluationMethodologyScreen from '../../evaluationMethodologies/EvaluationMethodologyScreen';
import ListEvaluationMethodologiesScreen from '../../evaluationMethodologies/list/ListEvaluationMethodologiesScreen';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';


type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_EVALUATIONS,
    EVALUATION
}

const ParametersEvaluationMethodologiesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_EVALUATIONS);
    const [currentEvaluationMethodology, setCurrentEvaluationMethodology] = useState<EvaluationMethodologyViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_EVALUATIONS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.EVALUATION_METHODOLOGIES}/> }
            { screen === ScreenType.LIST_EVALUATIONS && <ListEvaluationMethodologiesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewEvaluationMethodology={() => {
                    setIsDetails(false);
                    setCurrentEvaluationMethodology(null);
                    setScreen(ScreenType.EVALUATION)
                }}
                onClickItem={(item: EvaluationMethodologyViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentEvaluationMethodology(item);
                    setScreen(ScreenType.EVALUATION);
                }}
            ></ListEvaluationMethodologiesScreen>}
            { screen === ScreenType.EVALUATION && <EvaluationMethodologyScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                evaluationMethodologyId={currentEvaluationMethodology?.id}
                afterSave={() => {
                    setCurrentEvaluationMethodology(null);
                    setScreen(ScreenType.LIST_EVALUATIONS);
                }}></EvaluationMethodologyScreen>}
        </div>

    );
}

export default ParametersEvaluationMethodologiesScreen;
