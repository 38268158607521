import React, { useState } from 'react';

import ListDepartmentsScreen from '../../departments/list/ListDepartmentsScreen';
import DepartmentsScreen from '../../departments/DepartmentsScreen';
import { DepartmentViewModel } from '../../../api/departments/models/DepartmentViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_DEPARTMENTS,
    DEPARTMENT
}

const ParametersDepartmentsScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_DEPARTMENTS);
    const [currentDepartment, setCurrentDepartment] = useState<DepartmentViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_DEPARTMENTS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.DEPARTMENTS}/> }
            { screen === ScreenType.LIST_DEPARTMENTS && <ListDepartmentsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewDepartment={() => {
                    setIsDetails(false);
                    setCurrentDepartment(null);
                    setScreen(ScreenType.DEPARTMENT)
                }}
                onClickItem={(item: DepartmentViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentDepartment(item);
                    setScreen(ScreenType.DEPARTMENT);
                }}
            ></ListDepartmentsScreen>}
            { screen === ScreenType.DEPARTMENT && <DepartmentsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                departmentId={currentDepartment?.id}
                afterSave={() => {
                    setCurrentDepartment(null);
                    setScreen(ScreenType.LIST_DEPARTMENTS);
                }}></DepartmentsScreen>}
        </div>

    );
}

export default ParametersDepartmentsScreen;
