import dayjs, { OpUnitType } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

class Utils {

    public newGuid(): string {
        return uuidv4();
    }

    public objectArraybyStringPath(o: any, s: string) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    public getMonthsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'month')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'month');
        }

        return result;
    }

    public getPeriodsInRange(startDate: Date, endDate: Date, number: number, unit?: OpUnitType) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, unit)) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(number, unit);
        }

        return result;
    }

    public getYearsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'year')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'year');
        }

        return result;
    }

    public getMonthsInYear(date: Date) {
        return new Array(12).fill({}).map((_, i) => (new Date(date.getFullYear(), i, 1, 0, 0, 0, 0)));
    }


    public dateIsInRange(date:Date, startDate: Date, endDate: Date, unit?: OpUnitType) {
        const dDate = dayjs(date);
        const dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        return (dDate.isAfter(dStartDate) && dDate.isBefore(dEndDate)) ||
                dDate.isSame(dStartDate, unit) ||
                dDate.isSame(dEndDate, unit);
    }

    public getLowestDate(dates:(Date|undefined)[], dayjsFormat?: string) {
        let d : Date | null | undefined = null;
        dates.filter(x => !!x).forEach(x =>{
            d = !d || dayjs(x).format(dayjsFormat ?? 'YYYY/MM/DD') < dayjs(d).format(dayjsFormat ?? 'YYYY/MM/DD') ? x : d;
        })
        return d;
    }

    public getHighestDate(dates:(Date|undefined)[], dayjsFormat?: string) {
        let d : Date | null | undefined = null;
        dates.filter(x => !!x).forEach(x =>{
            d = !d || dayjs(x).format(dayjsFormat ?? 'YYYY/MM/DD') > dayjs(d).format(dayjsFormat ?? 'YYYY/MM/DD') ? x : d;
        })
        return d;
    }

    public calculateCellWidthStyle(width: number)  {
        return {
            width: this.calculateCellWidth(width),
        }
    }

    public calculateCellWidth(width: number)  {
        return  ((width * 100) / 20) + '%'
    }

    public isMobileView(width: number): boolean {
        return (width <= 1440);
    }

}

export default new Utils();
