import React, { memo } from 'react';
import styles from './SummernoteController.module.scss';
import { useTranslation } from 'react-i18next';
import { Controller, UseFormMethods } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export type Props = {
    isDisabled?: boolean;
    name: string;
    form: UseFormMethods<any>;
    placeholder?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const SummernoteController = ({ isDisabled, name, form, placeholder, ...props }: Props, ref: any) => {

    const { t } = useTranslation();

    const config = {
        'modules': {
            'toolbar': !isDisabled,
        }
    };
    return (

        <div className={isDisabled ? styles.disabled : styles.summernote} {...props} ref={ref}>
            <Controller
                render={({ onChange, value }) => {
                    return (
                        <ReactQuill {...config}
                            theme='snow'
                            placeholder={placeholder ? placeholder : t('common.add_text_here')}
                            readOnly={isDisabled}
                            value={value}
                            onChange={val => {
                                onChange(val);
                            }}
                        />
                    );
                }}
                name={name}
                control={form.control}
                defaultValue={form.getValues(name) || ''}
            />
        </div>
    );
};

export default memo(React.forwardRef<HTMLTextAreaElement, Props>(SummernoteController));
