import React, { useState } from 'react';
import ListUsersScreen from '../../users/list/ListUsersScreen';
import UsersScreen from '../../users/UsersScreen';
import { UserViewModel } from '../../../api/users/models/UserViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    establishmentId?: string;
};

enum ScreenType {
    LIST_USERS,
    USER
}

const ParametersUsersScreen: React.FC<Props> = ({ groupId, establishmentId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_USERS);
    const [currentUser, setCurrentUser] = useState<UserViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_USERS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.USERS}/> }
            { screen === ScreenType.LIST_USERS && <ListUsersScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                establishmentId={establishmentId}
                createNewUser={() => {
                    setIsDetails(false);
                    setCurrentUser(null);
                    setScreen(ScreenType.USER)
                }}
                onClickItem={(item: UserViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentUser(item);
                    setScreen(ScreenType.USER);
                }}
            ></ListUsersScreen>}
            { screen === ScreenType.USER && <UsersScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                establishmentId={establishmentId}
                userId={currentUser?.id}
                afterSave={() => {
                    setCurrentUser(null);
                    setScreen(ScreenType.LIST_USERS);
                }}></UsersScreen>}
        </div>

    );
}

export default ParametersUsersScreen;
