import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { EvaluationMethodologiesSearchCriteria } from './models/EvaluationMethodologiesSearchCriteria';
import { EvaluationMethodologyViewModel } from './models/EvaluationMethodologyViewModel';

class EvaluationMethodologiesService {
    
    public getList(criteria: EvaluationMethodologiesSearchCriteria) {
        return Http.get<Page<EvaluationMethodologyViewModel>>('evaluationMethodologies', criteria);
    }

    public getEvaluationMethodologyById(id: string) {
        return Http.get<EvaluationMethodologyViewModel>('evaluationMethodologies/'+ id);
    }

    public save(model: EvaluationMethodologyViewModel) {
        return !!model.id ? Http.put('evaluationMethodologies', model) : Http.post('evaluationMethodologies', model);
    }

    public remove(model: EvaluationMethodologyViewModel) {
        return  Http.put('evaluationMethodologies/deactivate', model);
    }

    public getEvaluationMethodologies(groupId?: string) {
        return Http.get<IdNameDto[]>('evaluationMethodologies/get-all-evaluation-methodologies-for-select-item/' + groupId);
    }
}

export default new EvaluationMethodologiesService();



