import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectsListDto } from './models/ProjectsListDto';
import { ProjectsSearchCriteria } from './models/ProjectsSearchCriteria';
import { ProjectViewModel } from './models/ProjectViewModel';

class ProjectService {

    public getList(criteria: ProjectsSearchCriteria) {
        return Http.get<Page<ProjectsListDto>>('projects', criteria);
    }

    public get(id: string) {
        return Http.get<ProjectViewModel>('projects/' + id);
    }

    public save(model: ProjectViewModel) {
        return model.id ? Http.put('projects', model) : Http.post('projects', model);
    }

    public remove(model: ProjectViewModel) {
        return Http.put('projects/deactivate', model);
    }
}

export default new ProjectService();