import Http from '../../common/services/Http';
import { IdNameDto } from '../common/models/IdNameDto';
import { Page } from '../common/types/Page';
import { SuccessIndicatorSearchCriteria } from './models/SuccessIndicatorSearchCriteria';
import { SuccessIndicatorViewModel } from './models/SuccessIndicatorViewModel';

class SuccessIndicatorsService {
    
    public getList(criteria: SuccessIndicatorSearchCriteria) {
        return Http.get<Page<SuccessIndicatorViewModel>>('successIndicators', criteria);
    }

    public getSuccessIndicatorById(id: string) {
        return Http.get<SuccessIndicatorViewModel>('successIndicators/'+ id);
    }

    public save(model: SuccessIndicatorViewModel) {
        return !!model.id ? Http.put('successIndicators', model) : Http.post('successIndicators', model);
    }

    public remove(model: SuccessIndicatorViewModel) {
        return  Http.put('successIndicators/deactivate', model);
    }

    public getSuccessIndicators(groupId?: string) {
        return Http.get<IdNameDto[]>('successIndicators/get-all-success-indicators-for-select-item/' + groupId);
    }
}

export default new SuccessIndicatorsService();



