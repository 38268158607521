import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import { useTranslation } from 'react-i18next';
import Toolbar from './toolbar/Toolbar';
import { SelectValueLabel } from '../../common/types/SelectValueLabel';
import TimesTable from './timesTable/TimesTable';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../common/components/pagination/Pagination';
import { CollaboratorsTimesSearchCriteria } from '../../api/collaboratorsTimes/models/CollaboratorsTimesSearchCriteria';
import { CollaboratorTimesDateDto, CollaboratorTimesDto, CollaboratorTimesValueDateDto } from '../../api/collaboratorsTimes/models/CollaboratorTimesDto';
import dayjs from 'dayjs';
import StatusService from '../../api/status/StatusService';
import ProjectResponsiblesDsiService from '../../api/projectResponsiblesDsi/ProjectResponsiblesDsiService';
import { useSelector } from 'react-redux';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';
import Loading from '../../common/services/Loading';
import SearchHistoryService from '../../api/userSearchHistory/SearchHistoryService';
import { SEARCH_HISTORY_PAGES } from '../../Config';
import { SearchHistoryViewModel } from '../../api/userSearchHistory/models/SearchHistoryViewModel';
import { useToasts } from 'react-toast-notifications';
import CollaboratorsTimesService from '../../api/collaboratorsTimes/CollaboratorsTimesService';
import Utils from '../../common/services/Utils';
import { ItemsPerPage } from '../../common/components/pagination/numberPerPage/NumberPerPage';
import { useHistory } from 'react-router-dom';
import { CollaboratorTimesSupportDto } from '../../api/collaboratorsTimes/models/CollaboratorTimesSupportDto';
import { CollaboratorTimesTotalsDto } from '../../api/collaboratorsTimes/models/CollaboratorTimesTotalsDto';

const generateDates = (dates: CollaboratorTimesDateDto[], start: Date, end: Date): CollaboratorTimesDateDto[] => {
    const result: CollaboratorTimesDateDto[] = [];
    let d = start;
    do {
        const items = dates.filter(x => dayjs(x.date).format('YYYY/MM/DD') == dayjs(d).format('YYYY/MM/DD'));
        const valuesP = items.filter(x => x.plannedValue != undefined).map(x => x.plannedValue);
        const valuesE = items.filter(x => x.executedValue != undefined).map(x => x.executedValue);
        result.push({
            date: new Date(items[0]?.date ?? d),
            plannedValue: valuesP.length > 0 ? valuesP.sum() : undefined,
            executedValue: valuesE.length > 0 ? valuesE.sum() : undefined,
            draw: dayjs(d).format('YYYY/MM/DD') <= dayjs(end).format('YYYY/MM/DD')
        });
        d = dayjs(d).add(1, 'month').toDate();
    } while (dayjs(d).format('YYYY/MM/DD') <= dayjs(end).format('YYYY/MM/DD') || result.length < 12);
    return result;
}

const generateDatesValues = (dates: CollaboratorTimesValueDateDto[], start: Date, end: Date): CollaboratorTimesValueDateDto[] => {
    const result: CollaboratorTimesValueDateDto[] = [];
    let d = start;
    do {
        const items = dates.filter(x => dayjs(x.date).format('YYYY/MM/DD') == dayjs(d).format('YYYY/MM/DD'));
        const values = items.filter(x => x.value != undefined).map(x => x.value);
        result.push({
            date: d,
            value: values.length > 0 ? values.sum() : undefined,
            draw: dayjs(d).format('YYYY/MM/DD') <= dayjs(end).format('YYYY/MM/DD')
        });
        d = dayjs(d).add(1, 'month').toDate();
    } while (dayjs(d).format('YYYY/MM/DD') <= dayjs(end).format('YYYY/MM/DD') || result.length < 12);

    return result;
}

const CollaboratorsTimes: React.FC = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [onInit, setOnInit] = useState(true);
    const [reRender, setReRender] = useState(1);
    const history = useHistory();

    const [collaborators, setCollaborators] = useState<SelectValueLabel[]>([]);
    const [collaboratorId, setCollaboratorId] = useState<string | undefined>(undefined);
    const [statusOptions, setStatusOptions] = useState<SelectValueLabel[]>([]);

    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPage>(0);
    const defaultCriteria = {
        page: 1,
        itemsPerPage: itemsPerPage,
        orderBy: 'asc',
        orderColumn: 'designation',
        groupId: loggedUser?.groupId,
        establishmentId: loggedUser?.establishmentId
    }
    const [criteria, setCriteria] = useState<CollaboratorsTimesSearchCriteria>({ ...defaultCriteria });
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [research, setResearch] = useState(0);
    const [openedRows, setOpenedRows] = useState<string[]>([]);

    const [collaboratorsTimes, setCollaboratorsTimes] = useState<CollaboratorTimesDto[] | undefined>();
    const [supportDates, setSupportDates] = useState<CollaboratorTimesDateDto[]>([]);
    const [totalPlannedDates, setTotalPlannedDates] = useState<CollaboratorTimesValueDateDto[]>([]);
    const [totalExecutedDates, setTotalExecutedDates] = useState<CollaboratorTimesValueDateDto[]>([]);

    const onToggleOpenRow = (id: string) => {
        if (openedRows.find(rId => rId === id)) {
            setOpenedRows([...openedRows.filter(rId => rId !== id)]);
        } else {
            setOpenedRows([...openedRows, id]);
        }
    }

    const getData = async () => {
        if (loggedUser) {
            const userPosition = { groupId: loggedUser.groupId, establishmentId: loggedUser.establishmentId };

            const [status, responsableDsi] =
                await Promise.all([
                    StatusService.getList(userPosition),
                    ProjectResponsiblesDsiService.getList(userPosition)
                ]);

            setStatusOptions(status.items.map(x => { return { value: x.id ?? '', label: x.name ?? '' } }) ?? []);
            setCollaborators(responsableDsi.items.map(x => { return { value: x.id ?? '', label: x.name ?? '' } }) ?? []);
        }
    }

    const getUserSettings = async () => {
        try {

            Loading.show();
            const resultSearchHistory = await SearchHistoryService.getSearchHistoryForPage(SEARCH_HISTORY_PAGES.COLLABORATORS_TIMES.toString());
            Loading.hide();
            applySearchHistory(resultSearchHistory);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
            getProjectsAndPhases();
        }
    }

    const applySearchHistory = (result: SearchHistoryViewModel) => {
        if (result && result.json) {
            const auxCriteria = JSON.parse(result.json);
            auxCriteria.groupId = loggedUser?.groupId;
            auxCriteria.establishmentId = loggedUser?.establishmentId;
            setOpenedRows(auxCriteria.openedRows);
            setCriteria(auxCriteria);
            setCollaboratorId(!!auxCriteria && !!auxCriteria.responsibleDsiId && auxCriteria.responsibleDsiId.length > 0 ? auxCriteria.responsibleDsiId[0] : null);
            setItemsPerPage(auxCriteria.itemsPerPage);
            search(auxCriteria.page);
        } else {
            setItemsPerPage(DEFAULT_PAGINATION_ITEMS_PER_PAGE);
            search();
        }
    }

    const getProjectsAndPhases = async () => {
        try {

            Loading.show();
            saveSearchHistory();

            const [result, totals, supports] =
                await Promise.all([
                    CollaboratorsTimesService.getProjectsAndPhases({ ...criteria, itemsPerPage }),
                    CollaboratorsTimesService.getProjectsAndSupportTotals(criteria),
                    CollaboratorsTimesService.getSupportTimes({ ...criteria }),
                ]);

            draw(result.items, totals, supports);
            setTotalItems(result.totalItems);
            setReRender(reRender + 1);
            Loading.hide();

        } catch (error) {
            console.log(error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const draw = (data: CollaboratorTimesDto[], dataTotals: CollaboratorTimesTotalsDto[], dataSupport: CollaboratorTimesSupportDto[]) => {

        let startDate = new Date(new Date().getFullYear(), 0, 1);
        let endDate = new Date(new Date().getFullYear(), 11, 31);

        const startDateProjects = Utils.getLowestDate([...data.map(x => x.startDate), ...dataSupport.map(x => x.date)]);
        if (startDateProjects) {
            startDate = new Date(startDateProjects);
        }
        if (!!criteria.startDate) {
            startDate = new Date(criteria.startDate);
        }

        const endDateProjects = Utils.getHighestDate([...data.map(x => x.endDate), ...dataSupport.map(x => x.date)]);
        if (endDateProjects) {
            endDate = new Date(endDateProjects);
        }
        if (!!criteria.endDate) {
            endDate = new Date(criteria.endDate);
        }

        const projects: CollaboratorTimesDto[] = data.map(x => {
            return {
                ...x,
                dates: generateDates(x.phases.map(x => x.dates).flat(), startDate, endDate),
                phases: x.phases.map(p => {
                    return {
                        ...p,
                        dates: generateDates(p.dates, startDate, endDate)
                    }
                }),
                showSubRows: !!criteria && !!criteria.openedRows && !!criteria.openedRows.find(c => c == x.id)
            }
        });

        const supports = generateDates((dataSupport && dataSupport.length ? dataSupport : []) as CollaboratorTimesDateDto[], startDate, endDate);
        setSupportDates(supports);

        const realizedDates: CollaboratorTimesValueDateDto[] = generateDatesValues([...(dataTotals??[]), ...supports].map(x => { return { date: x.date, value: x.executedValue } }), startDate, endDate);
        setTotalExecutedDates(realizedDates);

        const plannedDates: CollaboratorTimesValueDateDto[] = generateDatesValues([...(dataTotals??[]), ...supports].map(x => { return { date: x.date, value: x.plannedValue } }), startDate, endDate);
        setTotalPlannedDates(plannedDates);

        setCollaboratorsTimes(projects);
    }

    const saveSearchHistory = async () => {
        if (criteria) {
            criteria.openedRows = getOpenedRows();
            criteria.page = currentPage;
            criteria.responsibleDsiId = collaboratorId ? [collaboratorId] : undefined;
            criteria.itemsPerPage = itemsPerPage;
            setCriteria(criteria);
            await SearchHistoryService.saveSearchHistory({ page: SEARCH_HISTORY_PAGES.COLLABORATORS_TIMES.toString(), json: JSON.stringify(criteria) });
        }
    }

    const getOpenedRows = () => {
        return openedRows;
    }

    const search = (page?: number) => {
        setCurrentPage(page ?? 1);
        setResearch(research + 1);
    }

    const onItemsPerPageChange = (items: ItemsPerPage) => {
        setItemsPerPage(items);
        search();
    }

    const onRowClick = async (projectId: string) => {
        await saveSearchHistory();
        history.push(`/project/${projectId}/details/collaboratorstimes/null`);
    }

    useEffect(() => {
        if (onInit) {
            getData();
            getUserSettings();
            setOnInit(false);
        }
    }, [onInit]);

    useEffect(() => {
        if (research > 0) {
            getProjectsAndPhases();
        }
    }, [research]);

    return (
        <ScreenTitle title={t('collaborators_times.title')} >
            <ScreenContainer>
                <Toolbar
                    collaborators={collaborators}
                    collaboratorId={collaboratorId}
                    onChangeCollaborator={(collaboratorId?: string) => {
                        setCollaboratorId(collaboratorId);
                        search();
                    }}
                    clearSearch={() => {
                        setItemsPerPage(0);
                        setCollaboratorId(undefined);
                        setCriteria({ ...defaultCriteria })
                        setItemsPerPage(DEFAULT_PAGINATION_ITEMS_PER_PAGE);
                        setOpenedRows([]);
                        search();
                    }}
                    onItemsPerPageChange={onItemsPerPageChange}
                    itemsPerPage={itemsPerPage}
                />

                {collaboratorsTimes && <TimesTable
                    reRender={reRender}
                    criteria={criteria}
                    onChangeCriteria={(aux: CollaboratorsTimesSearchCriteria) => {
                        setCriteria(aux);
                        search();
                    }}
                    collaboratorsTimes={collaboratorsTimes}
                    statusOptions={statusOptions}
                    openRowsIds={openedRows}
                    onToggleOpenRow={onToggleOpenRow}
                    totalItems={totalItems}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    onPageChange={search}
                    supportDates={supportDates}
                    totalPlannedDates={totalPlannedDates}
                    totalExecutedDates={totalExecutedDates}
                    onRowClick={onRowClick}
                />}
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default CollaboratorsTimes;
