import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenContainer from '../../../../common/components/screenContainer/ScreenContainer';
import styles from './ProjectAdvancements.module.scss';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../../store/types';
import { UserProfile } from '../../../../api/account/models/UserProfile';
import Table, { TableCell, TableColumn, TableRow } from '../../../../common/components/table/Table';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../../../common/components/pagination/Pagination';
import Loading from '../../../../common/services/Loading';
import Text from '../../../../common/components/text/Text';
import Button from '../../../../common/components/button/Button';
import { EqualHeight } from 'react-equal-height';
import ProjectAdvancementTable from './ProjectAdvancementsTable/ProjectAdvancementTable';
import PaginationTextInfo from '../../../../common/components/pagination/PaginationTextInfo';
import DashboardService from '../../../../api/dashboards/DashboardService';
import { ProjectAdvancementsDashboardSearchCriteria } from '../../../../api/dashboards/models/ProjectAdvancementsDashboardSearchCriteria';
import { ColumnField, SearchField, SearchType } from '../../../../api/projects/models/ProjectsSearchCriteria';
import Utils from '../../../../common/services/Utils';
import { ProjectAdvancementsDashboardViewModel } from '../../../../api/dashboards/models/ProjectAdvancementsDashboardViewModel';
import DateFormat from '../../../../common/components/dateFormat/dateFormat';
import { DATE_FORMAT_MONTH_YEAR, SEARCH_HISTORY_PAGES } from '../../../../Config';
import SearchHistoryService from '../../../../api/userSearchHistory/SearchHistoryService';
import { SearchHistoryViewModel } from '../../../../api/userSearchHistory/models/SearchHistoryViewModel';
import Popover from '../../../../common/components/popover/Popover';
import { DomainViewModel } from '../../../../api/domains/models/DomainViewModel';
import { TypologiesViewModel } from '../../../../api/typologies/models/TypologiesViewModel';
import StatusService from '../../../../api/status/StatusService';
import DomainsService from '../../../../api/domains/DomainsService';
import TypologiesService from '../../../../api/typologies/TypologiesService';
import { StatusViewModel } from '../../../../api/status/models/StatusViewModel';
import { ProjectManagerViewModel } from '../../../../api/projectManagers/models/ProjectManagerViewModel';
import ProjectManagersService from '../../../../api/projectManagers/ProjectManagersService';
import PopoverFilters, { FiltersSetup } from '../../../../common/components/popoverFilters/PopoverFilters';
import dayjs from 'dayjs';
import ProjectAdvancementsTableFilters, { Filters } from './ProjectAdvancementsTableFilters/ProjectAdvancementsTableFilters';
import { useHistory } from 'react-router-dom';
import { ProjectPhaseViewModel } from '../../../../api/projects/models/ProjectViewModel';
import NumberPerPage, { ItemsPerPage } from '../../../../common/components/pagination/numberPerPage/NumberPerPage';
import { FaEraser } from 'react-icons/fa';

export interface ProjectAdvancement {
    id: string;
    name: string;
    dates: ProjectAdvancementDate[];
    phases: ProjectAdvancementPhase[];
    showPhases: boolean;
}

export interface ProjectAdvancementDate {
    date: Date;
    label: string;
    checked: boolean;
    filteredStart: boolean;
    filteredEnd: boolean;
}

export interface ProjectAdvancementPhase {
    name: string;
    order: number;
    percentage: number;
    dates: ProjectAdvancementPhaseDate[];
    countMonths: number;
    countMonthsFilteredStart: number;
    filteredStart: boolean;
    filteredEnd: boolean;
}

export interface ProjectAdvancementPhaseDate {
    date: Date;
    label: string;
    periods?: ProjectAdvancementPhaseDatePeriod[];
    periodsItems?: ProjectAdvancementPhaseDateItem[];
}

export interface ProjectAdvancementPhaseDatePeriod {
    date: Date;
}

export interface ProjectAdvancementPhaseDateItem {
    date: Date;
    checked: boolean;
    filteredStart: boolean;
    filteredEnd: boolean;
}

const ProjectAdvancements: React.FC = () => {

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [currentPage, setCurrentPage] = useState(1);
    const [projects, setProjects] = useState<ProjectAdvancementsDashboardViewModel[]>([]);
    const [onInit, setOnInit] = useState(true);
    const [projectPhases, setProjectPhases] = useState<ProjectAdvancement[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [filtersTotal, setFiltersTotal] = useState(0);
    const [reRenderContent, setReRenderContent] = useState(0);
    const [reRenderContentPhases, setReRenderContentPhases] = useState<number>(0);
    const history = useHistory();
    const [tableScrollLeft, setTableScrollLeft] = useState<number>(0);
    const [research, setResearch] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPage>(DEFAULT_PAGINATION_ITEMS_PER_PAGE);

    const [criteria, setCriteria] = useState<ProjectAdvancementsDashboardSearchCriteria>({
        page: 1,
        itemsPerPage: itemsPerPage,
        orderBy: 'asc',
        orderColumn: 'designation',
    });
    const [orderBy, setOrderBy] = useState<string | undefined>(criteria.orderBy);
    const [orderColumn, setOrderColumn] = useState<string | undefined>(criteria.orderColumn);
    const [forceOrderBy, setForceOrderBy] = useState(0);

    const defaultStartDate = new Date(new Date().getFullYear(), 0, 1);
    const defaultEndDate = new Date(new Date().getFullYear() + 5, 11, 31);
    const [filters, setFilters] = useState<Filters>();
    const [openedRows, setOpenedRows] = useState<string[]>([]);

    const [popoverFiltersSetup, setPopoverFiltersSetup] = useState<FiltersSetup[]>([]);

    const getData = async () => {
        if (loggedUser) {
            const userPosition = { groupId: loggedUser.groupId, establishmentId: loggedUser.establishmentId };

            const [status, projectManagers, domains, typologies] =
                await Promise.all([
                    StatusService.getList(userPosition), ProjectManagersService.getList(userPosition),
                    DomainsService.getList(userPosition), TypologiesService.getList(userPosition)
                ]);

            await setupPopoverFilters(status.items, projectManagers.items, domains.items, typologies.items);
        }
    }

    const setupPopoverFilters = async (status: StatusViewModel[], projectManagers: ProjectManagerViewModel[], domains: DomainViewModel[], typologies: TypologiesViewModel[]) => {
        const popoverSetup = [
            {
                key: 'firstRow',
                childs: [
                    {
                        key: 'projectManageId',
                        isSelect: true,
                        selectOptions: (projectManagers || []).map((x: any) => ({ value: x.id || '', label: x.name || '' })),
                        columnWidth: 6,
                        isDisabled: false,
                        label: t('projects.table.moa'),
                        labelClassname: styles.bold,
                        placeholder: t('projects.table.moa')
                    },
                    {
                        key: 'domainId',
                        isSelect: true,
                        selectOptions: (domains || []).map((x: any) => ({ value: x.id || '', label: x.name || '' })),
                        columnWidth: 6,
                        isDisabled: false,
                        label: t('projects.table.domain_project'),
                        labelClassname: styles.bold,
                        placeholder: t('projects.table.domain_project')
                    }
                ]
            },
            {
                key: 'secondRow',
                childs: [
                    {
                        key: 'typologyId',
                        isSelect: true,
                        selectOptions: (typologies || []).map((x: any) => ({ value: x.id || '', label: x.name || '' })),
                        columnWidth: 6,
                        isDisabled: false,
                        label: t('projects.table.typology_project'),
                        labelClassname: styles.bold,
                        placeholder: t('projects.table.typology_project')
                    },
                    {
                        key: 'statusId',
                        isSelect: true,
                        selectOptions: (status || []).map((x: any) => ({ value: x.id || '', label: x.name || '' })),
                        columnWidth: 6,
                        isDisabled: false,
                        label: t('projects.table.status'),
                        labelClassname: styles.bold,
                        placeholder: t('projects.table.status')
                    }
                ]
            },
        ];
        setPopoverFiltersSetup(popoverSetup);
    }

    const renderCellDates = (cell: TableCell) => (
        <>
            <DateFormat value={cell.row.startDate} format={DATE_FORMAT_MONTH_YEAR} /> - <DateFormat value={cell.row.endDate} format={DATE_FORMAT_MONTH_YEAR} />
        </>
    );

    const renderCellDesignation = (cell: TableCell) => (
        <label className={styles.clickable}
            onClick={(e: any) => {
                e.stopPropagation();
                onRowClick(cell.row)
            }}
        >
            {cell.row.designation}
        </label>
    );

    const _tableColumns: TableColumn[] = [
        { field: ColumnField.IDENTIFICATION, searchType: SearchType.TEXT, searchField: SearchField.IDENTIFICATION, order: 'identification', name: t('projects.table.identification'), width: '10%' },
        { field: ColumnField.DESIGNATION, searchType: SearchType.TEXT, searchField: SearchField.DESIGNATION, order: 'designation', name: t('projects.table.designation'), width: '50%', renderCell: renderCellDesignation },
        { field: ColumnField.START_END_DATE, searchType: SearchType.RANGE_DATE, searchField: SearchField.START_END_DATE, order: 'start_date', name: t('projects.table.start_end'), width: '40%', renderCell: renderCellDates, cellAlignment: 'center', columnCellAlignment: 'center' },
    ];

    const _tableSubColumns: TableColumn[] = [
        { field: 'name', name: t('projects.table.name'), width: '100%', cellAlignment: 'right', columnCellAlignment: 'right', onlyOneRow: true, cellStyle: { paddingRight: '1rem' } },
    ];

    const getUserSettings = async () => {
        try {

            Loading.show();
            const resultSearchHistory = await SearchHistoryService.getSearchHistoryForPage(SEARCH_HISTORY_PAGES.PROJECTS_ADVANCEMENTS_DASBOARD.toString());
            Loading.hide();

            applySearchHistory(resultSearchHistory);

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
            setDefaultFilters();
            search();
        }
    }

    const applySearchHistory = (result: SearchHistoryViewModel) => {
        if (result && result.json) {
            const auxCriteria = JSON.parse(result.json);
            setTableScrollLeft(auxCriteria.scrollLeft);
            setOrderBy(auxCriteria.orderBy);
            setOrderColumn(auxCriteria.orderColumn);
            setOpenedRows(auxCriteria.openedRows);
            setFilters(auxCriteria);
            updateTotalPopoverFilters(auxCriteria);
            setItemsPerPage(auxCriteria.itemsPerPage);
            search(auxCriteria.page);
        } else {
            setDefaultFilters();
            search();
        }
    }

    const getProjectsAndPhases = async () => {
        try {

            Loading.show();

            getCriteria();

            saveSearchHistory();
            const result = await DashboardService.getProjectsAndPhases(criteria);
            await generateProjectPhases(result.items.map(x => {
                return {
                    ...x,
                    showSubRows: !!x.id && isOpened(x.id)
                }
            }));
            setTotalItems(result.totalItems);
            setReRenderContent(reRenderContent + 1);

            const elTable = getTableElement();
            if (elTable) {
                elTable.scrollLeft = criteria.scrollLeft ?? 0;
            }

            Loading.hide();

        } catch (error) {
            console.log(error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const saveSearchHistory = async () => {
        const elTable = getTableElement();
        const scrollLeft = elTable ? elTable.scrollLeft : tableScrollLeft;
        criteria.scrollLeft = scrollLeft;
        criteria.openedRows = openedRows;
        setCriteria({ ...criteria });
        await SearchHistoryService.saveSearchHistory({ page: SEARCH_HISTORY_PAGES.PROJECTS_ADVANCEMENTS_DASBOARD.toString(), json: JSON.stringify(criteria) });
    }

    const getTableElement = () => {
        const el = document.getElementById('ProjectAdvancementsTableFooter');
        return !!el ? el : null;
    }

    const isOpened = (rowId: string) => {
        return !![...openedRows, ...(criteria.openedRows ?? [])].find(z => z == rowId);
    }

    const generateProjectPhases = async (result: ProjectAdvancementsDashboardViewModel[]) => {

        let s = filters?.startDate;
        let e = filters?.endDate;

        if (!s) {
            if (result.length > 0) {
                s = Utils.getLowestDate(result.filter(x => !!x.startDate).map(x => x.startDate ?? new Date()));
            } else {
                s = defaultStartDate;
            }
        }

        if (!e) {
            if (result.length > 0) {
                e = Utils.getHighestDate(result.filter(x => !!x.endDate).map(x => x.endDate ?? new Date()));
            } else {
                e = defaultEndDate;
            }
        }

        const startDateFilters = s ?? defaultStartDate;
        const endDateFilters = e ?? defaultEndDate;

        const months = Utils.getMonthsInRange(startDateFilters, endDateFilters);

        const list = await (result.length > 0 ? result : [{ designation: '', identification: '' }]).map((p, i) => {

            const project: ProjectAdvancement = {
                id: p.id ?? '', name: p.designation, dates: [], phases: [], showPhases: !!p.id && isOpened(p.id),
            }

            project.dates = months.map((m, i) => {
                const date = {
                    date: m,
                    label: t(('common.months_abbreviation.' + (m.getMonth() + 1)) as any),
                    checked: !!p.startDate && !!p.endDate && dayjs(m).format('YYYY/MM') >= dayjs(p.startDate).format('YYYY/MM') && dayjs(m).format('YYYY/MM') <= dayjs(p.endDate).format('YYYY/MM'),
                    filteredEnd: false,
                    filteredStart: false
                };
                date.filteredStart = date.checked && i == 0 && dayjs(m).format('YYYY/MM') > dayjs(p.startDate).format('YYYY/MM');
                date.filteredEnd = date.checked && (i == months.length - 1) && dayjs(m).format('YYYY/MM') < dayjs(p.endDate).format('YYYY/MM');
                return date;
            });

            const monthsProject = p.startDate && p.endDate ? Utils.getMonthsInRange(p.startDate, p.endDate) : [];

            project.phases = (p.phases || []).map((px, i) => {

                const s = px.dates.map(x => x.periods).flat().map(x => x?.dateStart);
                const e = px.dates.map(x => x.periods).flat().map(x => x?.dateEnd);

                let datesProject = buildProjectAdvancementPhases(monthsProject, px);

                if (!!s.length && e.length) {
                    const ss = Utils.getLowestDate(s);
                    const ee = Utils.getHighestDate(e);
                    if (!!ss && !!ee) {
                        datesProject = buildProjectAdvancementPhases(Utils.getMonthsInRange(ss, ee), px);
                    }
                }

                const dates = buildProjectAdvancementPhases(months, px);

                const phase = {
                    name: px.name,
                    order: px.order,
                    percentage: px.percentageAchievement,
                    countMonths: px.countMonths ?? 0,
                    countMonthsFilteredStart: !dates.length ? px.countMonths ?? 0 : datesProject.filter(x => x.periodsItems[0].checked && dayjs(x.date).format('YYYY/MM') < dayjs(dates[0].date).format('YYYY/MM')).length,
                    filteredStart: !!datesProject.find(x => x.periodsItems[0].checked && dayjs(x.date).format('YYYY/MM') < dayjs(startDateFilters).format('YYYY/MM')),
                    filteredEnd: !!datesProject.find(x => x.periodsItems[0].checked && dayjs(x.date).format('YYYY/MM') > dayjs(endDateFilters).format('YYYY/MM')),
                    dates: dates
                };

                return phase;

            });

            return project;

        });

        setProjectPhases(list);
        setProjects(result);
        setOpenedRows([]);
    }

    const buildProjectAdvancementPhases = (months: Date[], phase: ProjectPhaseViewModel) => {
        return months.map((m, i) => {

            const phasePeriods = phase.dates.filter(x => dayjs(x.date).format(x.dateFormat) == dayjs(m).format(x.dateFormat)).map(x => x.periods || []).flat();
            const checked = !!phasePeriods.find(x => {
                return dayjs(x?.dateStart).format('YYYY/MM/DD') <= dayjs(m).format('YYYY/MM/DD') && dayjs(x.dateEnd).format('YYYY/MM/DD') >= dayjs(m).format('YYYY/MM/DD');
            });

            const p = { date: m, checked, filteredStart: true, filteredEnd: false }

            p.filteredStart = checked && i == 0 && !!phasePeriods.find(x => {
                return dayjs(x?.dateStart).format('YYYY/MM/DD') < dayjs(m).format('YYYY/MM/DD');
            });

            p.filteredEnd = checked && (i == months.length - 1) && !!phasePeriods.find(x => {
                return dayjs(x.dateEnd).format('YYYY/MM/DD') > dayjs(m).format('YYYY/MM/DD');
            });

            const date = {
                date: m,
                label: t(('common.months_abbreviation.' + (m.getMonth() + 1)) as any),
                periodsItems: [p]
            };
            return date;
        });
    }

    const onOrderList = (column: TableColumn) => {
        const _orderBy = orderColumn !== column.order ? 'asc' : orderBy == 'desc' ? 'asc' : 'desc';
        setOrderColumn(column.order);
        setOrderBy(_orderBy);
        setForceOrderBy(forceOrderBy + 1);
        search();
    }

    const getCriteria = async () => {
        criteria.page = currentPage;
        criteria.groupId = loggedUser?.groupId;
        criteria.establishmentId = loggedUser?.establishmentId;
        criteria.orderBy = orderBy;
        criteria.orderColumn = orderColumn;
        criteria.openedRows = openedRows;
        criteria.itemsPerPage = itemsPerPage;
        if (filters) {
            criteria.identification = filters.identification;
            criteria.designation = filters.designation;
            criteria.startDate = filters.startDate;
            criteria.endDate = filters.endDate;
            criteria.projectManageId = filters.projectManageId;
            criteria.domainId = filters.domainId;
            criteria.typologyId = filters.typologyId;
            criteria.statusId = filters.statusId;
        }
        setCriteria({ ...criteria });
    };

    const onPageChange = (page: number) => {
        search(page);
    }

    const onItemsPerPageChange = (items: ItemsPerPage) => {
        setItemsPerPage(items);
        search();
    }

    const onChangeFilters = (filters: Filters) => {
        setCurrentPage(1);
        setFilters(filters);
        search();
    }

    const onClearFilters = (clearAll?: boolean) => {
        const f = clearAll ? {} : {
            identification: filters?.identification,
            designation: filters?.designation,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
        };
        setFilters(f);
        updateTotalPopoverFilters(f);
        setItemsPerPage(10);
        const elTable = getTableElement();
        if (elTable) {
            elTable.scrollLeft = 0;
        }
        setTableScrollLeft(0);
        search();
    }

    const setDefaultFilters = () => {
        setFilters(getDefaultFilters());
    }

    const getDefaultFilters = () => {
        return {
            // startDate: defaultStartDate,
            // endDate: defaultEndDate,
        };
    }

    const updateTotalPopoverFilters = (filters: Filters) => {
        let total = 0;

        if ((filters.projectManageId && filters.projectManageId.length > 0)) {
            total++;
        }
        if ((filters.domainId && filters.domainId.length > 0)) {
            total++;
        }
        if ((filters.typologyId && filters.typologyId.length > 0)) {
            total++;
        }
        if ((filters.statusId && filters.statusId.length > 0)) {
            total++;
        }

        setFiltersTotal(total);
    }


    const onRowClick = async (row: TableRow, index?: number) => {
        //if we only do the saveSearchHistory() he loses the scope and lost the criteria and filters!!!
        await saveSearchHistory();
        history.push(`/project/${row.id}/details/dashboards/null`);
    }

    const onExpandClick = (row: TableRow, index?: number) => {
        row.showSubRows = !row.showSubRows;
        if (index != null && index != undefined) {
            projectPhases[index].showPhases = row.showSubRows;
            if (openedRows.find(rId => rId === projectPhases[index].id)) {
                setOpenedRows([...openedRows.filter(rId => rId !== projectPhases[index].id)]);
            } else {
                setOpenedRows([...openedRows, projectPhases[index].id]);
            }
        }
        setReRenderContentPhases(reRenderContentPhases + 1);
    }

    const search = (page?: number) => {
        setCurrentPage(page ?? 1);
        setResearch(research + 1);
    }

    useEffect(() => {
        if (onInit) {
            getData();
            getUserSettings();
            setOnInit(false);
        }
    }, [onInit]);

    useEffect(() => {
        if (research > 0) {
            getProjectsAndPhases();
        }
    }, [research]);

    return (
        <ScreenContainer>
            <div className={styles.toolbar}>
                <Text preset="screenHeaderTitle" className={styles.toolbarTitle}>{t('dasboards.navbar.advancements')}</Text>
            </div>
            <div className={styles.numberOfPages} key={'contentTableTooltbar_' + reRenderContent}>
                <NumberPerPage itemsPerPage={itemsPerPage} onChangeItemsPerPage={onItemsPerPageChange}></NumberPerPage>
                {
                    filters && popoverFiltersSetup && popoverFiltersSetup.length > 0 &&
                    <Popover
                        positions={['bottom']}
                        onClickOutside={() => updateTotalPopoverFilters(filters)}
                        content={setIsPopoverOpen => <PopoverFilters
                            filtersSetup={popoverFiltersSetup}
                            filters={filters}
                            onFilter={popoverFilters => { setIsPopoverOpen(false); onChangeFilters(popoverFilters) }}
                            onChange={popoverFilters => updateTotalPopoverFilters(popoverFilters)}
                            onClearFilter={() => { setIsPopoverOpen(false); onClearFilters() }}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <Button preset="secondary"
                                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                className={styles.btnFilters}>
                                {t('common.filters')}
                                {filtersTotal > 0 &&
                                    <div className={styles.counter}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </Button>
                        )}
                    </Popover>
                }
                <Button onClick={() => {
                    onClearFilters(true);
                }} preset="primary" onlyIcon={true} className={styles.toolbarButton}>
                    <FaEraser className={styles.toolbarButtonIcon} />
                </Button>
            </div>
            <EqualHeight>
                <div key={'contentTable_' + reRenderContent}
                    className={styles.divTablesContent}>
                    <div className={styles.divTablesLeft}>
                        {projects && projectPhases.length > 0 && <Table
                            columns={_tableColumns}
                            rows={projects}
                            allowHover={true}
                            searchComponent={filters && <ProjectAdvancementsTableFilters
                                filters={filters}
                                onFilter={filters => { onChangeFilters(filters) }}
                                onChange={filters => {
                                    //updateFilters
                                }}
                                columns={_tableColumns}
                                onClearFilters={onClearFilters}
                            />}
                            onOrderList={column => onOrderList(column as TableColumn)}
                            subRowsFieldName={'phases'}
                            subRowsColumns={_tableSubColumns}
                            onRowClick={onExpandClick}
                            onExpandClick={onExpandClick}
                            orderBy={orderBy}
                            orderColumn={orderColumn}
                        />}
                    </div>
                    <div className={styles.divTablesRight} id="divRightTable">
                        <ProjectAdvancementTable projects={projectPhases} reRenderContentPhases={reRenderContentPhases}></ProjectAdvancementTable>
                    </div>
                </div>
            </EqualHeight>
            {totalItems > 0 && <div className={styles.paginationContainer}>
                <PaginationTextInfo className={styles.paginationInfo} itemName={t('projects.items')} items={projects.length} totalItems={totalItems} />
                <Pagination currentPage={currentPage} onChange={onPageChange} totalItems={totalItems} itemsPerPage={itemsPerPage} />
            </div>}
        </ScreenContainer>
    );
}

export default ProjectAdvancements;
