import React, { useState } from 'react';
import ListPriorityLevelsScreen from '../../priorityLevels/list/ListPriorityLevelsScreen';
import PriorityLevelsScreen from '../../priorityLevels/PriorityLevelsScreen';
import { PriorityLevelViewModel } from '../../../api/priorityLevels/models/PriorityLevelViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_PRIORITY_LEVELS,
    PRIORITY_LEVEL
}

const ParametersPriorityLevelsScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_PRIORITY_LEVELS);
    const [currentPriorityLevel, setCurrentPriorityLevel] = useState<PriorityLevelViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_PRIORITY_LEVELS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.PRIORITY_LEVELS}/> }
            { screen === ScreenType.LIST_PRIORITY_LEVELS && <ListPriorityLevelsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewPriorityLevel={() => {
                    setIsDetails(false);
                    setCurrentPriorityLevel(null);
                    setScreen(ScreenType.PRIORITY_LEVEL)
                }}
                onClickItem={(item: PriorityLevelViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentPriorityLevel(item);
                    setScreen(ScreenType.PRIORITY_LEVEL);
                }}
            ></ListPriorityLevelsScreen>}
            { screen === ScreenType.PRIORITY_LEVEL && <PriorityLevelsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                priorityLevelId={currentPriorityLevel?.id}
                afterSave={() => {
                    setCurrentPriorityLevel(null);
                    setScreen(ScreenType.LIST_PRIORITY_LEVELS);
                }}></PriorityLevelsScreen>}
        </div>

    );
}

export default ParametersPriorityLevelsScreen;
