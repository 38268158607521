import React, { useState } from 'react';

import ListEstablishmentsScreen from '../../establishments/list/ListEstablishmentsScreen';
import EstablishmentsScreen from '../../establishments/EstablishmentsScreen';
import { EstablishmentViewModel } from '../../../api/establishments/models/EstablishmentViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string;
    onRefreshAccordion?: () => void;
};

enum ScreenType {
    LIST_ESTABLISHMENTS,
    ESTABLISHMENT
}

const ParametersEstablishmentsScreen: React.FC<Props> = ({ groupId, onRefreshAccordion, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_ESTABLISHMENTS);
    const [currentEstablishment, setCurrentEstablishment] = useState<EstablishmentViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_ESTABLISHMENTS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.ESTABLISHMENTS}/> }
            { screen === ScreenType.LIST_ESTABLISHMENTS &&
                <ListEstablishmentsScreen
                    userCanRead={userCanRead}
                    userCanWrite={userCanWrite}
                    groupId={groupId}
                    createNewEstablishment={() => {
                        setIsDetails(false);
                        setCurrentEstablishment(null);
                        setScreen(ScreenType.ESTABLISHMENT)
                    }}
                    onClickItem={(item: EstablishmentViewModel, isDetails: boolean) => {
                        setIsDetails(isDetails);
                        setCurrentEstablishment(item);
                        setScreen(ScreenType.ESTABLISHMENT);
                    }}
                    onRefreshAccordion={onRefreshAccordion}
                ></ListEstablishmentsScreen>}
            { screen === ScreenType.ESTABLISHMENT && <EstablishmentsScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                isDetailsToTab={false}
                groupId={groupId}
                establishmentId={currentEstablishment?.id}
                afterSave={() => {
                    setCurrentEstablishment(null);
                    setScreen(ScreenType.LIST_ESTABLISHMENTS);
                    onRefreshAccordion && onRefreshAccordion();
                }}></EstablishmentsScreen>}
        </div>

    );
}

export default ParametersEstablishmentsScreen;
