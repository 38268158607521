import React, { useState } from 'react';
import ListPolesScreen from '../../poles/list/ListPolesScreen';
import PolesScreen from '../../poles/PolesScreen';
import { PoleViewModel } from '../../../api/poles/models/PoleViewModel';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    establishmentId?: string;
};

enum ScreenType {
    LIST_POLES,
    POLE
}

const ParametersPolesScreen: React.FC<Props> = ({ establishmentId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_POLES);
    const [currentPole, setCurrentPole] = useState<PoleViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_POLES && <ListPolesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                establishmentId={establishmentId}
                createNewPole={() => {
                    setIsDetails(false);
                    setCurrentPole(null);
                    setScreen(ScreenType.POLE)
                }}
                onClickItem={(item: PoleViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentPole(item);
                    setScreen(ScreenType.POLE);
                }}
            ></ListPolesScreen>}
            { screen === ScreenType.POLE && <PolesScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                establishmentId={establishmentId}
                poleId={currentPole?.id}
                afterSave={() => {
                    setCurrentPole(null);
                    setScreen(ScreenType.LIST_POLES);
                }}></PolesScreen>}
        </div>

    );
}

export default ParametersPolesScreen;
