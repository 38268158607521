import React, { useState } from 'react';
import ListPartnersScreen from '../../partners/list/ListPartnersScreen';
import PartnersScreen from '../../partners/PartnersScreen';
import { PartnersViewModel } from '../../../api/partners/models/PartnersViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead: boolean;
    userCanWrite: boolean;
    groupId?: string
};

enum ScreenType {
    LIST_PARTNERS,
    PARTNERS
}

const ParametersPartnersScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_PARTNERS);
    const [currentPartners, setCurrentPartners] = useState<PartnersViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_PARTNERS && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.PARTNERS}/> }
            { screen === ScreenType.LIST_PARTNERS && <ListPartnersScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                groupId={groupId}
                createNewPartners={() => {
                    setIsDetails(false);
                    setCurrentPartners(null);
                    setScreen(ScreenType.PARTNERS)
                }}
                onClickItem={(item: PartnersViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentPartners(item);
                    setScreen(ScreenType.PARTNERS);
                }}
            ></ListPartnersScreen>}
            { screen === ScreenType.PARTNERS && <PartnersScreen
                userCanRead={userCanRead}
                userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                partnersId={currentPartners?.id}
                afterSave={() => {
                    setCurrentPartners(null);
                    setScreen(ScreenType.LIST_PARTNERS);
                }}></PartnersScreen>}
        </div>

    );
}

export default ParametersPartnersScreen;
