import React, { useState } from 'react';
import ListStrategicAxesScreen from '../../strategicAxes/list/ListStrategicAxesScreen';
import StrategicAxesScreen from '../../strategicAxes/StrategicAxesScreen';
import { StrategicAxesViewModel } from '../../../api/strategicAxes/models/StrategicAxesViewModel';
import GroupParamsInfo from './groupParamsInfo/GroupParamsInfo';
import { GROUP_PARAMS_TYPE } from '../../../Config';

type Props = {
    userCanRead:boolean;
    userCanWrite:boolean;
    groupId?: string
};

enum ScreenType {
    LIST_STRATEGIC_AXES,
    STRATEGIC_AXES
}

const ParametersStrategicAxesScreen: React.FC<Props> = ({ groupId, userCanRead, userCanWrite }: Props) => {

    const [screen, setScreen] = useState<ScreenType>(ScreenType.LIST_STRATEGIC_AXES);
    const [currentStrategicAxes, setCurrentStrategicAxes] = useState<StrategicAxesViewModel | null>(null);
    const [isDetails, setIsDetails] = useState<boolean>(true);

    return (
        <div>
            { screen === ScreenType.LIST_STRATEGIC_AXES && <GroupParamsInfo userCanRead={userCanRead} userCanWrite={userCanWrite} groupId={groupId} type= {GROUP_PARAMS_TYPE.STRATEGIC_AXES}/> }

            { screen === ScreenType.LIST_STRATEGIC_AXES && <ListStrategicAxesScreen
            userCanRead={userCanRead}
            userCanWrite={userCanWrite}
                groupId={groupId}
                createNewStrategicAxes={() => {
                    setIsDetails(false);
                    setCurrentStrategicAxes(null);
                    setScreen(ScreenType.STRATEGIC_AXES)
                }}
                onClickItem={(item: StrategicAxesViewModel, isDetails: boolean) => {
                    setIsDetails(isDetails);
                    setCurrentStrategicAxes(item);
                    setScreen(ScreenType.STRATEGIC_AXES);
                }}
            ></ListStrategicAxesScreen>}
            { screen === ScreenType.STRATEGIC_AXES && <StrategicAxesScreen
            userCanRead={userCanRead}
            userCanWrite={userCanWrite}
                isDetails={isDetails}
                groupId={groupId}
                strategicAxesId={currentStrategicAxes?.id}
                afterSave={() => {
                    setCurrentStrategicAxes(null);
                    setScreen(ScreenType.LIST_STRATEGIC_AXES);
                }}></StrategicAxesScreen>}
        </div>

    );
}

export default ParametersStrategicAxesScreen;
