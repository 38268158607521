import React, { useState } from 'react';
import styles from './Popover.module.scss';
import { Popover as TinyPopover, PopoverState } from 'react-tiny-popover';

type Props = {
    onClickOutside?: () => void;
    content?: (setIsPopoverOpen: (v: boolean) => void) => JSX.Element;
    contentContainerClassName?: string;
    children: (isOpen: boolean, setIsPopoverOpen: (v: boolean) => void) => JSX.Element;
    positions?: ('left' | 'right' | 'top' | 'bottom')[] | undefined;
    align?: ('start' | 'center' | 'end') | undefined;
    parent?: HTMLElement,
    containerClassName?: string;
};

const Popover: React.FC<Props> = ({ onClickOutside, content, contentContainerClassName, children, positions, align, parent, containerClassName }: Props) => {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const setIsOpen = (v: boolean) => setIsPopoverOpen(v);

    const onClose = () => {
        onClickOutside && onClickOutside();
        setIsOpen(false)
    }

    return (
        <TinyPopover
            isOpen={isPopoverOpen}
            positions={positions || ['left', 'top']}
            align={align||'end'}
            reposition={true}
            content={(popoverState: PopoverState) => {
                let height = window.innerHeight - 30;
                if(popoverState.boundaryRect.height < height){
                    height = popoverState.boundaryRect.height;
                }
                return <div className={`${styles.content} ${contentContainerClassName ? contentContainerClassName : ''}`}
                    style={{ maxHeight: height }}
                >
                    {content && content(setIsOpen)}
                </div>
            }}
            containerStyle={{
                marginTop: '10px',
                zIndex: '6'
            }}
            // onClickOutside={onClose}
            boundaryElement={parent}
            parentElement={parent}
        >
            <div className={`${containerClassName ? containerClassName : ''}`}>
                {isPopoverOpen && <div className={styles.overlayContainer} onClick={onClose} onContextMenu={onClose}></div>}
                {children(isPopoverOpen, setIsOpen)}
            </div>
        </TinyPopover>
    );
}

export default Popover;
