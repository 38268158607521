import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { ProjectResponsibleSearchCriteria } from './models/ProjectResponsibleSearchCriteria';
import { ProjectResponsibleViewModel } from './models/ProjectResponsibleViewModel';


class ProjectResponsiblesService {
    
    public getList(criteria: ProjectResponsibleSearchCriteria) {
        return Http.get<Page<ProjectResponsibleViewModel>>('projectResponsibles', criteria);
    }

    public getProjectResponsibleById(id: string) {
        return Http.get<ProjectResponsibleViewModel>('projectResponsibles/'+ id);
    }

    public save(model: ProjectResponsibleViewModel) {
        return !!model.id ? Http.put('projectResponsibles', model) : Http.post('projectResponsibles', model);
    }

    public remove(model: ProjectResponsibleViewModel) {
        return  Http.put('projectResponsibles/deactivate', model);
    }
}

export default new ProjectResponsiblesService();