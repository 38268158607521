import Http from '../../common/services/Http';
import { GroupSearchCriteria } from './models/GroupSearchCriteria';
import { GroupViewModel } from './models/GroupViewModel';
import { IdNameDto, IdNameWithChildsDto } from '../common/models/IdNameDto';

class GroupService {

    public getList(criteria: GroupSearchCriteria) {
        return Http.get<GroupViewModel[]>('groups', { params: criteria as any });
    }

    public get(id?: string) {
        return Http.get<GroupViewModel>('groups/' + id);
    }

    public save(model: GroupViewModel) {
        return !!model.id ? Http.put('groups', model) : Http.post('groups', model);
    }

    public getAllGroupsForSelectItem() {
        return Http.get<IdNameDto[]>('groups/get-all-establishment-groups-for-select-item');
    }

    public getAllGroupsForSelectItemWithEstablishments() {
        return Http.get<IdNameWithChildsDto[]>('groups/get-all-groups-for-select-item-with-establishments');
    }

    public getGroupWithEstablishments(id: string) {
        return Http.get<IdNameWithChildsDto>('groups/get-group-with-establishments/' + id);
    }

    public remove(model: GroupViewModel) {
        return  Http.put('groups/deactivate', model);
    }
}

export default new GroupService();